import { useQueryClient } from '@tanstack/react-query';
import { httpAddReceiver, httpRequestPaymentLink } from 'app/api/estimate';
import LateDeliveryNotice from 'app/components/LateDeliveryNotice';
import { staticLocationTypes } from 'app/constants';
import {
  useFetchEstimateQuery,
  useGetEstimations,
  useGetStructureEstimate,
} from 'app/hooks/dispatch';
import { useGetProfile } from 'app/hooks/user';
import { useRequestDataPayload } from 'app/hooks/utils';

import WalletSlideIn from 'app/sender/components/WalletSlideIn';
import {
  PARCEL_CODE,
  STATE_DISPATCH_PAYLOAD,
  STATE_REQUEST_PAYLOAD,
} from 'app/state/constants';
import { getCurrentCountry } from 'app/utilities/country';
import { cleanNullInObject, isCommerce } from 'app/utilities/helpers';
import {
  DestinationInterface,
  EstimateProps,
  GeoLocationData,
  HttpDispatchResponse,
  LocationTagValues,
  LocationWrapperValues,
  UserDetailsValues,
} from 'app/utilities/types/shared';
import { Form, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import { isEmpty, range, reduce, size } from 'lodash';
import defer from 'lodash/defer';
import isDeepEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ElrButton, ElrLeftArrowButton, ElrModal } from 'ui-components';
import '../styles.css';
import { DestinationDetails } from './DestinationDetails';
import { DestinationInput, DispatchDetailsNav } from './DispatchDetailUnits';
import PaymentLink from './PaymentLink';

type Props = Pick<
  FormikHelpers<any>,
  'setFieldValue' | 'setValues' | 'submitForm'
> & {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  isDispatchDetails?: boolean;
  requestSuccess?: boolean;
  onSetUserRouteAddresses?: (routes: LocationWrapperValues) => void;
  senderInformation?: UserDetailsValues;
  geolocation?: GeoLocationData;
};

const EstimateForm: React.FC<Props> = ({
  values,
  errors,
  setFieldValue,
  setValues,
  isDispatchDetails,
  submitForm,
  requestSuccess,
  onSetUserRouteAddresses,
  senderInformation,
  geolocation,
}) => {
  // set current step to 0 or 1 if it's on the recipient page or not
  const detailByStep = isDispatchDetails ? 1 : 0;
  const queryClient = useQueryClient();
  const country = getCurrentCountry();

  const storeId = useParams().id || undefined;
  const [requestDataPayload] = useRequestDataPayload();

  const [estimateDetailCache, estimateRoutes] = useGetEstimations();
  const [estimatePayload] = useGetStructureEstimate(
    estimateRoutes as EstimateProps
  );
  const [currentStep, setCurrentStep] = useState(detailByStep);
  const [phoneValidityState, setPhoneValidityState] = useState(false);
  const [proceedOrder, setProceedOrder] = useState(false);
  const [showLateDeliveryOverlay, setShowLateDeliveryOverlay] = useState(false);
  const [locationWrapper, setLocationWrapper] = useState<LocationWrapperValues>(
    {
      pickupLocation: { id: '', label: '', distance: 0, duration: 0 },
      dropoffLocations: [],
      parcelCode: '',
    }
  );

  const { id: userId, currentAccessRole, role } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const isACommerce = isCommerce(currentRole);

  const [isAdding, setIsAdding] = useState<boolean[]>([false]);

  const [showSlider, setShowSlider] = useState(false);
  const [requestingPaymentLink, setRequestingPaymentLink] = useState(false);
  const [link, setLink] = useState('');

  const parcelCodeSelection = (queryClient.getQueryData([PARCEL_CODE]) as {
    code: string;
  }) || { code: '' };
  const [parcelCodeValue, setParcelCode] = useState(parcelCodeSelection.code);

  const onSelectParcelType = (option: { value: string; code: string }) => {
    setParcelCode(option.code);
    setFieldValue(PARCEL_CODE, option.code);
    invalidate();
  };

  const [estimateDetails, setEstimateDetails] =
    useState<any>(estimateDetailCache);
  const [sendEstimateQuery] = useFetchEstimateQuery();

  const { pickupLocation, dropoffLocations: locationDestination } =
    locationWrapper;
  const filterPayload = locationDestination.filter((f) => f?.id && f?.label);
  const entryComplete = Boolean(
    locationWrapper.pickupLocation && filterPayload.length
  );

  let isLoading = false;

  const invalidate = (returningNewAddress?: boolean) => {
    if (!returningNewAddress) {
      queryClient.invalidateQueries({ queryKey: [STATE_DISPATCH_PAYLOAD] });
      setEstimateDetails({});
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const next = (steps: number) => {
    if (currentStep < steps) {
      setCurrentStep((step) => step + 1);
      scrollToTop();
    }
  };

  const previous = () => {
    if (currentStep > 0) {
      setCurrentStep((step) => step - 1);
      scrollToTop();
    }
  };

  const prepopulateEstimates = useCallback(() => {
    if (estimateRoutes) {
      setLocationWrapper((lw) => ({ ...lw, ...estimatePayload }));
      setValues({
        ...values,
        ...estimatePayload,
      });
    }
  }, [estimateRoutes]);

  const fetchEstimate = memoizeOne(async (byPass?: boolean) => {
    if ((isEmpty(estimateDetails) && entryComplete) || byPass) {
      try {
        const data = await sendEstimateQuery({
          pickupLocation,
          dropoffLocations: filterPayload,
          parcelCode: parcelCodeValue,
        });
        setEstimateDetails(data);

        return data;
      } catch {
        /** noop */
      }
    }
    isLoading = false;
    return null;
  }, isDeepEqual);

  fetchEstimate();

  useEffect(() => {
    prepopulateEstimates();
  }, []);

  const onLocationInputChange = (
    locationType: string,
    value: LocationTagValues,
    returningNewAddress?: boolean
  ): void => {
    const isPickupType = locationType === staticLocationTypes.pickupLocation;
    const isDestinationType =
      locationType === staticLocationTypes.dropoffLocations;

    if (isPickupType) {
      setLocationWrapper((lw) => ({ ...lw, pickupLocation: { ...value } }));
      onSetUserRouteAddresses &&
        onSetUserRouteAddresses({
          ...locationWrapper,
          pickupLocation: { ...value },
        });
    } else if (isDestinationType) {
      onSetUserRouteAddresses &&
        onSetUserRouteAddresses({
          ...locationWrapper,
          dropoffLocations: value as any,
        });
      setLocationWrapper((lw) => ({ ...lw, dropoffLocations: value as any }));
    }

    invalidate(returningNewAddress);
    setFieldValue(staticLocationTypes[locationType], value);

    if (parcelCodeSelection) {
      setFieldValue(PARCEL_CODE, parcelCodeSelection.code);
    }
  };

  const phoneValidityStatus = memoizeOne((status: boolean) => {
    defer(() => {
      setPhoneValidityState(status);
    }, 'deferred');
  });

  const normalizedSteps = reduce(
    range(1, values.dropoffLocations.length + 1),
    (memo, step) => ({
      ...memo,
      [step]: () => (
        <DestinationDetails
          phoneValidityStatus={phoneValidityStatus}
          key={step}
          index={step - 1}
          values={values}
          setValues={setValues}
          setIsAdding={setIsAdding}
        />
      ),
    }),
    {
      0: () => (
        <DestinationInput
          onLocationInputChange={onLocationInputChange}
          values={values}
          errors={errors}
          onSelectParcelType={onSelectParcelType}
        />
      ),
    } as any
  );
  const steps = ['Estimate', ...values.dropoffLocations];

  const onSelectRecipientOption = (i: number) => {
    if (steps[i]) {
      setCurrentStep(i);
    }
  };

  const disablePlaceOrderBtn = () => {
    const filterDropOff = values.dropoffLocations.filter(Boolean);
    return (
      size(errors) > 0 ||
      !phoneValidityState ||
      size(values.deliverToInformation) < size(filterDropOff)
    );
  };

  const errorMessage =
    disablePlaceOrderBtn() && 'Complete your delivery details to place order';

  const renderComponent = normalizedSteps[currentStep];

  const onProceed = async () => {
    if (userId) {
      const beneficiaries: DestinationInterface[] = [];
      isAdding.forEach(async (isAdd, index) => {
        if (isAdd) {
          beneficiaries.push(
            cleanNullInObject({
              name: values.deliverToInformation[index].name,
              phone: values.deliverToInformation[index].phone,
              packageType:
                values.deliverToInformation[index].packageType?.value,
              packageValue: values.deliverToInformation[index].packageValue,
              packageDetail: values.deliverToInformation[index].packageDetail,
              deliveryNotes: values.deliverToInformation[index].deliveryNotes,
              address: cleanNullInObject({
                fullAddress: values.dropoffLocations[index].label,
                placeId: values.dropoffLocations[index].id,
              }),
            })
          );
        }
      });
      await addReceiver(beneficiaries);
    }
    setProceedOrder(true);
    getRequestPayload();
  };

  const addReceiver = async (beneficiary: DestinationInterface[]) => {
    await httpAddReceiver({ userId, beneficiary });
  };

  const onRequestNow = () => {
    const hour = new Date().getHours();
    if (hour >= 14) return setShowLateDeliveryOverlay(true);
    return submitForm();
  };

  const onProceedWithWalletOption = () => {
    setValues({ ...values, paymentOption: 'wallet' }, false);
    submitForm();
    setProceedOrder(false);
  };

  const isLastStep = !steps[currentStep + 1] || currentStep >= steps.length - 1;

  const getRequestPayload = () => {
    const clientQueryPayload = {
      geoId: '',
      batch: false,
    };

    const dispatchPayload: HttpDispatchResponse | typeof clientQueryPayload =
      queryClient.getQueryData([STATE_DISPATCH_PAYLOAD]) || clientQueryPayload;

    const payload = requestDataPayload({
      geoId: dispatchPayload.geoId,
      data: values,
      senderInformation,
      geolocation,
      dispatcherId: storeId,
      batch: dispatchPayload.batch,
      country,
    });

    queryClient.prefetchQuery({
      queryKey: [STATE_REQUEST_PAYLOAD],
      queryFn: () => payload,
      gcTime: 24000000, // ~40 minutes+
    });
    return payload;
  };

  const requestPaymentLink = async () => {
    if (userId) {
      setRequestingPaymentLink(true);

      const requestPayload = getRequestPayload();

      try {
        const result = await httpRequestPaymentLink(
          userId,
          requestPayload,
          storeId
        );
        if (result) {
          setLink(result);
          toggleSlider();
        }
        setRequestingPaymentLink(false);
      } catch (error: any) {
        setRequestingPaymentLink(false);
      }
    }
    setProceedOrder(true);
  };

  const toggleSlider = () => setShowSlider((p) => !p);

  const disableOrder = disablePlaceOrderBtn() || requestSuccess;

  const placeOrderText = () => {
    if (disableOrder && !isLastStep) return 'Next';
    return 'Place Order';
  };

  const placeOrderNotAllowed = disableOrder && isLastStep;

  const onClickPlaceOrderBtn = () => {
    if (disableOrder && !isLastStep) return next(steps.length - 1);
    return onProceed();
  };

  return (
    <div className="w-full">
      {proceedOrder && (
        <WalletSlideIn
          onUserSelectWalletOption={onProceedWithWalletOption}
          showSlideIn={proceedOrder}
          setShowSlideIn={setProceedOrder}
        />
      )}
      {isDispatchDetails && (
        <DispatchDetailsNav
          onSelectRecipientOption={onSelectRecipientOption}
          currentStep={currentStep}
          steps={steps}
        />
      )}
      {currentStep > 0 && (
        <div className="mb-5">
          <span className="mr-3 text-lg font-normal">Package Details</span>
          <span className="text-xs opacity-60">Delivery {currentStep}</span>
        </div>
      )}
      <Form>{renderComponent()}</Form>
      <div className="relative">
        <br />
        {showLateDeliveryOverlay && (
          <LateDeliveryNotice
            closeModal={setShowLateDeliveryOverlay}
            onProceed={submitForm}
          />
        )}
        {!isDispatchDetails ? (
          <div className="flex w-full justify-end">
            {isACommerce ? (
              <ElrButton
                onClick={onRequestNow}
                text="Request now"
                loading={isLoading}
                disabled={isLoading}
                spinnerColor="White"
                className="w-44 h-9 bg-elr-black text-elr-white-400 md:text-base"
              />
            ) : null}
          </div>
        ) : (
          <>
            <div className="flex w-full">
              <div className="mr-4">
                <ElrLeftArrowButton
                  disabled={!currentStep}
                  onClick={previous}
                />
              </div>
              <ElrButton
                disabled={placeOrderNotAllowed}
                onClick={onClickPlaceOrderBtn}
                loading={requestSuccess}
                spinnerColor="White"
                text={placeOrderText()}
                className="w-36 h-9 bg-elr-black text-elr-white-400 md:text-base"
              />
              <ElrButton
                disabled={
                  disablePlaceOrderBtn() ||
                  requestSuccess ||
                  requestingPaymentLink
                }
                onClick={requestPaymentLink}
                loading={requestingPaymentLink}
                text="Let Someone Pay"
                className="ml-5 w-36 h-9 bg-elr-gray-500 text-elr-black md:text-base"
              />
            </div>
            {errorMessage && (
              <span>
                <p className="mt-2 text-xs text-elr-green text-opacity-60">
                  {errorMessage}
                </p>
              </span>
            )}
            <ElrModal
              height="h-120 md:h-540"
              isOpen={showSlider}
              onClose={toggleSlider}
            >
              <PaymentLink link={link} />
            </ElrModal>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(EstimateForm);
