import {
  BoxIcon,
  CheckboxIcon,
  CopyDoubleIcon,
  EBikeIcon,
  SearchIcon,
} from 'app/components/SvgAssets';
import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import { ERRANDLR_REDIRECT_URL } from 'app/constants';
import {
  getDropdownIconClassNames,
  getRequestStatusClassNames,
  getTabClassNames,
} from 'app/dispatcher/modules/components/ClassUtils';
import {
  useFetchDeliveriesMade,
  useFetchNewRequests,
  useFetchUpcomingRequests,
} from 'app/hooks/requests/dashboard';
import { useGetProfile } from 'app/hooks/user';
import copyToClipboard from 'app/utilities/copyToClipboard';
import { currencyFormatter, getFirstItem } from 'app/utilities/helpers';
import { publicRoutes } from 'app/utilities/routes';
import { Request, RequestTypes } from 'app/utilities/types/shared';
import Dropdown from 'assets/images/dropdown.svg';
import { truncate } from 'lodash';
import { DateTime } from 'luxon';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ElrButton, ElrDropdownButton, ElrPageTitle } from 'ui-components';
import { ElrButtonPreIcon } from 'ui-components/components/ElrButton';

interface Props {}

/* eslint-disable-next-line no-shadow */
enum TabNames {
  NEW_REQUESTS = 'New Requests',
  IN_TRANSIT = 'In-Transit',
  DELIVERED = 'Delivered',
}

const DashboardContainer: React.FC<Props> = ({}) => {
  const {
    accountInformation: { slug = '' },
  } = useGetProfile();

  const businessDeliveryLink = `${ERRANDLR_REDIRECT_URL}/${slug}`;

  const [activeTab, setActiveTab] = useState(TabNames.NEW_REQUESTS);
  const [underlinePosition, setUnderlinePosition] = useState({
    left: 0,
    width: 0,
  });
  const newRequestsTabRef = useRef<HTMLButtonElement | null>(null);
  const inTransitTabRef = useRef<HTMLButtonElement | null>(null);
  const deliveredTabRef = useRef<HTMLButtonElement | null>(null);
  const [isExpanded, setIsExpanded] = useState<number | null>(null);

  const { latestData: newRequestsData, isLoading: isLoadingNewRequests } =
    useFetchNewRequests();
  const {
    latestData: upcomingRequestsData,
    isLoading: isLoadingUpcomingRequests,
  } = useFetchUpcomingRequests();
  const {
    latestData: deliveriesMadeRequestData,
    isLoading: isLoadingDeliveriesRequests,
  } = useFetchDeliveriesMade();

  const toggleRow = (index: number) => {
    setIsExpanded(isExpanded === index ? null : index);
  };

  const tabs = [
    { name: TabNames.NEW_REQUESTS, ref: newRequestsTabRef },
    { name: TabNames.IN_TRANSIT, ref: inTransitTabRef },
    { name: TabNames.DELIVERED, ref: deliveredTabRef },
  ];

  useEffect(() => {
    const activeTabRef = tabs.find((tab) => tab.name === activeTab)?.ref
      .current;
    if (activeTabRef) {
      const activeTabWidth = activeTabRef.offsetWidth;
      setUnderlinePosition({
        left: activeTabRef.offsetLeft + (activeTabWidth - 110) / 2,
        width: 110,
      });
    }
  }, [activeTab]);

  const newRequests =
    newRequestsData?.docs.map((request: Request) => ({
      ...request,
      status:
        request.status === RequestTypes.open
          ? RequestTypes.open
          : request.status,
    })) || [];

  const inTransitRequests =
    upcomingRequestsData?.docs.map((request: Request) => ({
      ...request,
      status:
        request.status === RequestTypes.accepted
          ? RequestTypes.accepted
          : request.status,
    })) || [];

  const deliveredRequests =
    deliveriesMadeRequestData?.docs.map((request: Request) => ({
      ...request,
      status:
        request.status === RequestTypes.completed
          ? RequestTypes.completed
          : request.status,
    })) || [];

  const filteredDocuments = (() => {
    switch (activeTab) {
      case TabNames.NEW_REQUESTS:
        return newRequests;
      case TabNames.IN_TRANSIT:
        return inTransitRequests;
      case TabNames.DELIVERED:
        return deliveredRequests;
      default:
        return [];
    }
  })();

  const cardData = [
    {
      title: TabNames.NEW_REQUESTS,
      count: newRequestsData?.totalDocs || 0,
      Icon: <CheckboxIcon fill="#FFF9E4" bgFill="#FCCD33" />,
    },
    {
      title: TabNames.IN_TRANSIT,
      count: upcomingRequestsData?.totalDocs || 0,
      Icon: <EBikeIcon fill="#EFEDFE" bgFill="#5A4AF2" />,
    },
    {
      title: TabNames.DELIVERED,
      count: deliveriesMadeRequestData?.totalDocs || 0,
      Icon: <BoxIcon />,
    },
  ];

  const _renderLoadState = () => {
    const isLoading =
      isLoadingNewRequests ||
      isLoadingUpcomingRequests ||
      isLoadingDeliveriesRequests;

    if (isLoading) {
      return (
        <tr>
          <td
            colSpan={4}
            className="text-center italic py-8 text-elr-black-300"
          >
            Loading...
          </td>
        </tr>
      );
    }

    if (!filteredDocuments.length) {
      const emptyMessages: Record<string, React.ReactNode> = {
        [TabNames.NEW_REQUESTS]: (
          <>
            There are no new requests.&nbsp;
            <span>
              Create a&nbsp;
              <a
                href={publicRoutes.estimate}
                className="cursor-pointer underline"
                target="_blank"
                rel="noreferrer"
              >
                new order here
              </a>
            </span>
          </>
        ),
        [TabNames.IN_TRANSIT]: (
          <>There are no requests in transit at the moment.</>
        ),
        [TabNames.DELIVERED]: <>No deliveries have been made yet.</>,
      };

      return (
        <tr>
          <td
            colSpan={4}
            className="text-center italic py-8 text-elr-black-300"
          >
            {emptyMessages[activeTab] || 'No data available.'}
          </td>
        </tr>
      );
    }
    return null;
  };

  const handleCopy = () => {
    copyToClipboard(businessDeliveryLink);
    toast.success('Business Delivery link copied');
  };

  return (
    <div className="">
      <ElrPageTitle title="Errandlr - Logistics" />
      <ProfileNavigationMenu>
        <div className="flex justify-between w-full md:mx-auto items-center">
          <TopNavigationMenu containerClass="md:mr-0" />
        </div>
        <div className="bg-elr-gray flex flex-col py-8 px-7 md:pt-3 md:px-20">
          <div className="mb-6 text-elr-black font-normal font-serif leading-tight">
            <h3 className="pt-6 text-xl mb-2">Logistics</h3>
            <p className="text-elr-black-300 text-base">
              View and manage deliveries
            </p>
          </div>

          <DashboardCards data={cardData} />

          <section className="px-8 py-4 flex justify-between bg-elr-white-400 mb-5">
            <div className="md:flex md:flex-row items-center flex-col">
              <p>Business Delivery Link &nbsp; - &nbsp;</p>
              <div className="flex items-center gap-2 align-middle pt-2 md:pt-0">
                <p className="text-elr-purple bg-elr-gray py-2 px-3">
                  {businessDeliveryLink}
                </p>
                <div className="md:hidden flex p-2 bg-elr-gray">
                  <CopyDoubleIcon fill="#5a4af2" />
                </div>
              </div>
            </div>
            <div className="md:flex gap-x-4 hidden">
              <ElrButton
                text={'Learn More'}
                onClick={() => true}
                className="bg-elr-gray text-elr-black rounded-md py-3 px-5"
              />
              <ElrButtonPreIcon
                text={'Copy Link'}
                icon={<CopyDoubleIcon />}
                onClick={handleCopy}
                className="bg-elr-black flex items-center justify-center text-elr-white-400 rounded-md py-3 px-5"
              />
            </div>
          </section>

          <section className="bg-elr-white-400 mb-8">
            <div className="py-4 md:px-8 px-2 md:flex justify-between border-b-2 border-elr-gray">
              <div className="flex self-center text-lg md:gap-16 justify-between border-elr-black-200 relative">
                {tabs.map((tab) => (
                  <button
                    key={tab.name}
                    type="button"
                    ref={tab.ref}
                    className={getTabClassNames(activeTab === tab.name)}
                    onClick={() => setActiveTab(tab.name)}
                  >
                    {tab.name}
                  </button>
                ))}
                <div
                  className={`rounded-t-lg h-1 bg-elr-black absolute transition-all duration-300 ease-in-out`}
                  style={{
                    width: `${underlinePosition.width}px`,
                    left: `${underlinePosition.left}px`,
                    bottom: '-24px',
                  }}
                />
              </div>
              <div className="flex gap-4 md:flex-row flex-col">
                <div className="mt-12 md:mt-0">
                  <ElrDropdownButton
                    text="Single Delivery"
                    isToggled={false}
                    className="bg-elr-gray rounded-sm px-12"
                    size="lg"
                  />
                </div>
                <div className="md:flex justify-between gap-4 bg-elr-gray px-4 py-2 rounded-sm w-72 hidden">
                  <input
                    type="text"
                    placeholder="search address or tracking ID"
                    className="outline-none w-full bg-elr-gray border-none focus:outline-0"
                  />
                  <SearchIcon />
                </div>
              </div>
            </div>

            <div className="overflow-x-auto w-full">
              <table className="table-auto w-full">
                <thead>
                  <tr className="border-b-2 border-elr-gray text-elr-black">
                    <th className="font-normal py-7 text-left md:pl-32 md:w-1/3  pl-20 flex-grow truncate">
                      Delivery Destination
                    </th>
                    <th className="font-normal py-7 text-center flex-grow">
                      Sender
                    </th>
                    <th className="font-normal py-7 text-center flex-grow">
                      Tracking ID
                    </th>
                    <th className="font-normal py-7 text-center flex-grow">
                      Amount
                    </th>
                    {activeTab === TabNames.IN_TRANSIT && (
                      <th className="font-normal py-7 text-center flex-grow">
                        Status
                      </th>
                    )}
                    <th className="font-normal py-7 text-center w-24" />
                  </tr>
                </thead>
                <tbody>
                  {filteredDocuments.length
                    ? filteredDocuments.map(
                        (request: Request, index: number) => (
                          <Fragment key={request.trackingId}>
                            <TableRow
                              request={request}
                              index={index}
                              activeTab={activeTab}
                              toggleRow={toggleRow}
                              isExpanded={isExpanded}
                            />
                            {isExpanded === index && (
                              <ExpandedRow
                                request={request}
                                activeTab={activeTab}
                              />
                            )}
                          </Fragment>
                        )
                      )
                    : _renderLoadState()}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default DashboardContainer;

interface CardData {
  id?: string;
  title: string;
  count: number;
  Icon: React.ReactNode;
}

interface DashboardCardsProps {
  data: CardData[];
}

export const DashboardCards: React.FC<DashboardCardsProps> = ({ data }) => (
  <section className="grid md:grid-cols-column-three grid-cols-column gap-5 mb-5">
    {data.map((card) => (
      <div key={card.title} className="bg-elr-white-400 py-5 px-6">
        <div>
          <div className="rounded-full p-2 inline-block">{card.Icon}</div>
          <div className="mb-5" />
          <p className="text-lg text-elr-black-300 leading-5 mb-3">
            {card.title}
          </p>
          <p className="text-3xl text-elr-black">{card.count}</p>
        </div>
      </div>
    ))}
  </section>
);

interface TableRowProps {
  request: Request;
  index: number;
  activeTab: TabNames;
  toggleRow: (index: number) => void;
  isExpanded: number | null;
}

export const TableRow: React.FC<TableRowProps> = ({
  request,
  index,
  activeTab,
  toggleRow,
  isExpanded,
}) => {
  const {
    pickupAddress,
    trackingId,
    priceEstimate,
    senderInformation,
    status,
  } = request;

  const formatDateTime = (createdAt: string) => {
    const dateTime = DateTime.fromISO(createdAt);
    return dateTime.toFormat('yyyy-LL-dd');
  };

  return (
    <tr className="text-center border border-x-0 border-elr-gray-500">
      <td className="text-left py-5 pl-7 text-elr-black truncate md:max-w-xs max-w-1/10">
        <div className="truncate">{pickupAddress?.fullAddress || 'N/A'}</div>
        <div className="text-elr-black-300 text-sm pt-1">
          {formatDateTime(request.createdAt)}
        </div>
      </td>
      <td className="py-5 text-center text-elr-black text-opacity-60">
        {truncate(senderInformation?.data?.name || 'N/A', { length: 20 })}
      </td>
      <td className="py-5 text-center text-elr-black text-opacity-60">
        {trackingId || 'N/A'}
      </td>
      <td className="py-5 text-center text-elr-black text-opacity-60">
        {currencyFormatter(priceEstimate)}
      </td>
      {activeTab === TabNames.IN_TRANSIT && (
        <td className="py-5 text-center text-elr-black text-opacity-60">
          <p className={getRequestStatusClassNames(status)}>
            {status === RequestTypes.accepted ? 'Assigned' : 'Picked Up'}
          </p>
        </td>
      )}
      <td className="py-5 relative">
        <button
          className="focus:outline-none"
          type="button"
          onClick={() => toggleRow(index)}
        >
          <img
            src={Dropdown}
            alt="Dropdown"
            className={getDropdownIconClassNames(isExpanded === index)}
          />
        </button>
      </td>
    </tr>
  );
};

interface ExpandedRowProps {
  request: Request;
  activeTab: TabNames;
}
export const ExpandedRow: React.FC<ExpandedRowProps> = ({
  request,
  activeTab,
}) => {
  const {
    deliverToInformation,
    pickupAddress,
    designatedAgent,
    estimatedTime,
    destinationArea,
  } = request;
  const recipientInfo = getFirstItem(deliverToInformation);
  const riderName =
    designatedAgent?.firstname || designatedAgent?.lastname
      ? `${designatedAgent.firstname || ''} ${designatedAgent.lastname || ''}`
      : 'N/A';
  const riderPhone = designatedAgent?.phone || 'N/A';

  return (
    <tr className="text-left">
      <td colSpan={5} className="p-5 text-elr-black md:pl-56 pl-20">
        <div className="flex flex-col gap-4">
          <div className="flex justify-between align-middle items-center">
            <div className="space-y-5 relative">
              <div className="flex items-center">
                <div className="inline-block w-[8px] h-[8px] rounded-full bg-elr-purple" />
                <p className="ml-3">{pickupAddress?.fullAddress || 'N/A'}</p>
              </div>
              <div className="absolute left-1 top-0 bottom-0 border-l-2 border-dashed h-6 border-elr-gray-200" />
              <div className="flex items-start">
                <span className="w-[8px] h-[8px] bg-elr-yellow rounded-sm mt-1.5" />
                <p className="ml-3">
                  {destinationArea?.area ||
                    recipientInfo?.address.fullAddress ||
                    'N/A'}
                </p>
              </div>
            </div>
          </div>
          <div className="flex w-full p-8 bg-elr-gray md:gap-40 gap-16">
            <div className="flex flex-col">
              <p>Recipient Details</p>
              <p className="text-elr-black text-opacity-60 py-1">
                {recipientInfo?.name || 'N/A'}
              </p>
              <p className="text-elr-black text-opacity-60">
                {recipientInfo?.phone || 'N/A'}
              </p>
            </div>
            <div className="flex flex-col">
              <p>Package Info</p>
              <p className="text-elr-black text-opacity-60 py-1">
                {recipientInfo?.packageType}
              </p>
              <p className="text-elr-black text-opacity-60">
                {recipientInfo?.packageValue}
              </p>
            </div>
            {activeTab === TabNames.NEW_REQUESTS ? (
              <div className="flex flex-col">
                <p>Estimated Time</p>
                <p className="text-elr-black text-opacity-60 py-1">
                  {estimatedTime || '1hr 30mins'}
                </p>
              </div>
            ) : (
              <div className="flex flex-col">
                <p>Rider Details</p>
                <p className="text-elr-black text-opacity-60 py-1">
                  {riderName}
                </p>
                <p className="text-elr-black text-opacity-60">{riderPhone}</p>
              </div>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};
