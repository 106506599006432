import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpUpdateDispatcherProfile } from 'app/api/dispatcher';
import { useGetProfile } from 'app/hooks/user';
import { queryKeys } from 'app/utilities/query';
import { Session } from 'app/utilities/Session';
import { AreasOfOperations } from 'app/utilities/types/dispatcherTypes';
import { IOptions } from 'app/utilities/types/shared';
import { capitalize, isEmpty } from 'lodash';
import { mergeAll } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { ElrButton, ElrMainSelect, ElrStateSelect } from 'ui-components';
import { v4 as uuidv4 } from 'uuid';

export const checkAlreadySelectedOption = (
  options: IOptions[],
  value: string
): number => {
  const checkOption = options.filter((opt) => opt.value === value);
  return checkOption.length;
};

const OperationAreas: React.FC = () => {
  const [cityOptions, setCityOptions] = useState<IOptions[]>([]);
  const [updated, setUpdated] = useState(false);
  const [countryOptions, setCountryOptions] = useState<IOptions[]>([]);
  const [selectionStructure, setStructure] = useState<{
    [key: string]: { [key: string]: boolean };
  }>({});
  const uuid = uuidv4();
  const profile = useGetProfile();
  const {
    id,
    accountInformation: { id: dispatcherId, areasOfOperations },
  } = profile;
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: AreasOfOperations) =>
      httpUpdateDispatcherProfile(payload, id),

    onSuccess: (data) => {
      queryClient.setQueryData(queryKeys.user, (oldData: object | undefined) =>
        oldData ? { ...oldData, accountInformation: data } : {}
      );
    },
  });

  const tenantData = Session.tenantData();

  const convertDbSavedDataToSelectionStructure = useCallback(() => {
    const aop = areasOfOperations;
    return (
      aop?.reduce(
        (prevValue, { country, states: dbStates }) => ({
          ...prevValue,
          [country]: dbStates.reduce(
            (obj, cur) => ({ ...obj, [cur]: true }),
            {}
          ),
        }),
        {}
      ) || {}
    );
  }, []);

  useEffect(() => {
    if (areasOfOperations?.length && isEmpty(selectionStructure)) {
      const newStructure = convertDbSavedDataToSelectionStructure();
      const newCountries = areasOfOperations.map(({ country }, index) => ({
        label: country,
        value: country,
        id: index,
      }));
      const newStates = areasOfOperations
        .map(({ states, country }) =>
          states.map((curr, index) => ({
            label: curr,
            value: `${curr}|${country}`,
            id: index,
          }))
        )
        .flat();
      setCountryOptions(newCountries);
      setCityOptions(newStates);
      setStructure(newStructure);
    }
  }, [
    areasOfOperations,
    convertDbSavedDataToSelectionStructure,
    selectionStructure,
  ]);

  const statesRequired = Object.keys(selectionStructure)
    .map((r) => isEmpty(selectionStructure[r]) && r)
    .filter(Boolean);

  const onChange = (selectedOption: any): void => {
    const { value = '', label = '' } = selectedOption || {};

    if (Object.keys(tenantData).includes(value)) {
      if (checkAlreadySelectedOption(countryOptions, value)) return;

      const options: IOptions[] = [
        ...countryOptions,
        { value, id: countryOptions.length + 1, label },
      ];

      setCountryOptions(options);
      setCountryOptionRequirement(value);
      return;
    }

    if (checkAlreadySelectedOption(cityOptions, value)) return;
    if (value) {
      const newOptions: IOptions[] = [
        ...cityOptions,
        { value, id: cityOptions.length + 1, label },
      ];
      setCityOptions(newOptions);

      const [state, country] = value.split('|');
      const lowerCaseState = state.toLowerCase();

      setStateOptionRequirement(country, lowerCaseState);
    }
  };

  const setCountryOptionRequirement = (country: string): void => {
    setStructure((prev) => ({
      ...prev,
      [country]: {},
    }));
  };

  const setRemoveCountryOption = (country: string) => {
    const options = { ...selectionStructure };
    delete options[country];
    // delete the states that have the country from the select options
    const newOptions: IOptions[] = [...cityOptions];
    const newFilteredCityOption = newOptions.filter(
      (opt) => !opt.value.includes(country)
    );

    setStructure({ ...options });
    setCityOptions(newFilteredCityOption);
  };

  const setRemoveStateOption = (stateValue: string) => {
    const options = { ...selectionStructure };
    const [state, country] = stateValue.split('|');
    const lowerCaseState = state.toLowerCase();
    delete options[country][lowerCaseState];
    setStructure({ ...options });
  };

  const setStateOptionRequirement = (country: string, state: string): void => {
    setStructure((prev) => ({
      ...prev,
      [country]: {
        ...prev[country],
        [state]: true,
      },
    }));
  };

  const getCountry = () => countryOptions.map((country: any) => country.value);

  const deleteOptions = (index: number, type: string) => {
    if (type === 'city') {
      const newOptions: IOptions[] = [...cityOptions];
      const splitOption = newOptions.splice(index, 1);
      setCityOptions(newOptions);
      setRemoveStateOption(splitOption[0].value);
      return;
    }
    const options: IOptions[] = [...countryOptions];
    const splitOption = options.splice(index, 1);
    setCountryOptions(options);
    setRemoveCountryOption(splitOption[0].value);
  };

  const countries = Object.keys(selectionStructure);
  const states = mergeAll(Object.values(selectionStructure));

  const makeUpdate = async () => {
    try {
      const aop = Object.keys(selectionStructure).map((country) => ({
        country,
        states: Object.keys(selectionStructure[country]),
      }));

      const payload = {
        areasOfOperations: aop,
        dispatcherId,
      };

      await mutateAsync(payload);
      setUpdated(true);
    } catch (error) {
      setUpdated(false);
      /** noop */
    }
  };

  const hasDataInDb = areasOfOperations?.length;
  const onGotoNext = async () => {
    await makeUpdate();
  };

  return (
    <div>
      <div className="mt-5 mb-8 text-xl text-center"> Area of Operations </div>
      <div>
        <div className="h-32">
          <ElrMainSelect
            placeholder="Search countries*"
            onChange={onChange}
            value={{ label: '', value: '' }}
            data={Object.keys(tenantData)}
          />
          <div className="flex flex-wrap">
            {countries.map((country: string, i: number) => (
              <div
                key={`${uuid}${country}`}
                className="text-sm flex items-center h-6 bg-elr-white-400 border border-elr-gray-600 border-opacity-30 p-1 px-1.5 rounded-2xl mr-2 mb-2"
              >
                {capitalize(country)}
                <span
                  onClick={() => deleteOptions(i, 'country')}
                  className="p-1 cursor-pointer text-elr-gray-600"
                >
                  x
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="h-48">
          <ElrStateSelect
            placeholder="Search states*"
            onChange={onChange}
            countries={getCountry()}
            value={{ label: '', value: '' }}
          />
          <div className="flex flex-wrap">
            {Object.keys(states).map((city: string, i: number) => (
              <div
                key={city}
                className="text-sm flex items-center h-6 bg-elr-white-400 border border-elr-gray-600 border-opacity-30 p-1 px-1.5 rounded-2xl mr-2 mb-2"
              >
                {capitalize(city)}
                <span
                  onClick={() => deleteOptions(i, 'city')}
                  className="p-1 cursor-pointer text-elr-gray-600"
                >
                  x
                </span>
              </div>
            ))}
          </div>

          <div
            className={`text-xs mb-1 h-3.5 text-elr-green ${
              statesRequired.length ? 'visible' : 'hidden'
            }`}
          >
            State is required for {statesRequired.join(', ')}.
          </div>
        </div>
      </div>
      <div>
        <div className="w-full mb-10 border-b border-elr-gray-600 md:mb-12 opacity-60" />
        <div className="flex justify-end">
          <div>
            <ElrButton
              text="Save"
              disabled={
                (!hasDataInDb && Boolean(statesRequired.length)) ||
                !countries.length ||
                isPending
              }
              className="w-40 mb-3 text-lg text-white bg-elr-black"
              onClick={onGotoNext}
            />
          </div>
        </div>
        {updated && (
          <p className="text-sm animate-bounce"> Updated successfuly </p>
        )}
        <div className="mb-10 md:mb-12" />
      </div>
    </div>
  );
};

export default React.memo(OperationAreas);
