import BussinessInfoForm from '../BusinessInfoForm';

const MarketRuns = () => (
  <div className="flex justify-center pt-16">
    <div className="flex flex-col justify-center w-full px-4 md:w-8/12 md:px-0">
      <BussinessInfoForm />
    </div>
  </div>
);

export default MarketRuns;
