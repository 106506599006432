import SlideIn from 'app/components/SlideIn/SlideIn';
import { MoneyIcon, PencilIcon, PlusIcon } from 'app/components/SvgAssets';
import {
  ArchiveDrawerWithBG,
  ProfileIconWithBG,
} from 'app/operator/components/SvgAssets';
import { currencyFormatter } from 'app/utilities/helpers';
import { useState } from 'react';
import {
  ElrInput,
  ElrLocationSelect,
  ElrModal,
  ElrPhoneInputFlat,
} from 'ui-components';
import {
  ElrButton,
  ElrButtonPreIcon,
} from 'ui-components/components/ElrButton';
import TimePicker from 'ui-components/components/ErlTimePicker';
import ErrorIcon from '../../../../assets/images/error-icon.svg';
import CSVIcon from '../../../../assets/images/csv_with_bg.svg';

const MarketDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSalesModalOpen, setIsSalesModalOpen] = useState<boolean>(false);
  const [isUpdateProductModalOpen, setIsUpdateProductModalOpen] =
    useState<boolean>(false);

  const deactivateSales = false;

  const cardData = [
    {
      title: 'Total Sales',
      count: currencyFormatter(50000),
      Icon: <MoneyIcon />,
    },
    {
      title: 'Total Added Products',
      count: 300,
      Icon: <ArchiveDrawerWithBG />,
    },
    {
      title: 'Total Orders',
      count: 500,
      Icon: <ProfileIconWithBG />,
    },
  ];

  const renderEditInfo = (
    <SlideIn
      close={() => setIsModalOpen(false)}
      shown={isModalOpen}
      leftMarginOffset="md:ml-[60%]"
    >
      <p className="text-center pb-6 text-xl">Edit Business Information</p>
      <form>
        <div className="w-full py-8 mx-auto mb-10 bg-elr-white-400">
          <div className="flex flex-col gap-6">
            <div className="w-full">
              <p className="text-base pb-2 text-elr-black-400">
                Business Address
              </p>
              <div className="h-12">
                <ElrLocationSelect
                  className="pr-2"
                  controlHeight={44}
                  currentValue=""
                  placeholder="Address"
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="w-full">
              <p className="text-base pb-2 text-elr-black-400">Phone Number</p>
              <ElrPhoneInputFlat
                containerClass="w-full flex !mb-0"
                className="border-none bg-elr-gray h-12"
                onChange={() => {}}
                placeholder="Phone Number"
              />
            </div>
            <div className="w-full">
              <p className="text-base pb-2 text-elr-black-400">Email Address</p>
              <ElrInput
                onChange={() => {}}
                name="businessEmail"
                className="border-none rounded-none bg-elr-gray !h-12 outline-none"
                placeholder="Email Address"
              />
            </div>
            <div className="w-full">
              <p className="text-base pb-2 text-elr-black-400">Opening time</p>
              <div className="flex items-center justify-between w-full h-12 bg-elr-gray">
                <TimePicker
                  className="w-full bg-elr-gray"
                  placeholder="Opening Time"
                  clearIcon={null}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="w-full">
              <p className="text-base pb-2 text-elr-black-400">Closing Time</p>
              <div className="flex items-center justify-between w-full h-12 bg-elr-gray">
                <TimePicker
                  className="w-full bg-elr-gray"
                  placeholder="Closing Time"
                  clearIcon={null}
                  onChange={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
        <ElrButton
          text="Save Changes"
          className="w-full bg-elr-black text-white rounded-md text-base mt-24"
          submit
          disabled
        />
      </form>
    </SlideIn>
  );

  return (
    <div className="bg-elr-gray flex flex-col pt-8 px-7 md:pt-3 md:px-20 min-h-screen">
      <section className="flex justify-between pb-8">
        <div className=" text-elr-black font-normal font-serif leading-tight">
          <h3 className="pt-6 text-xl mb-2">E-commerce</h3>
          <p className="text-elr-black-300 text-base">
            View and follow up your products and sales
          </p>
        </div>
        {isSalesModalOpen && (
          <SalesTurnOffModal
            isOpen={isSalesModalOpen}
            onClose={() => setIsSalesModalOpen(false)}
            onClick={() => {}}
            loading={false}
          />
        )}
        {isUpdateProductModalOpen && (
          <UpdateProductModal
            isOpen={isUpdateProductModalOpen}
            onClose={() => setIsUpdateProductModalOpen(false)}
            onClick={() => {}}
            loading={false}
          />
        )}
        <div className="flex gap-4 items-center">
          {!deactivateSales ? (
            <ElrButton
              text={'Deactivate Sales'}
              disabled={false}
              loading={false}
              onClick={() => setIsSalesModalOpen(true)}
              className="bg-elr-white-400 text-elr-error rounded-sm py-3 px-5"
            />
          ) : (
            <ElrButton
              text={'Sales Deactivated'}
              disabled
              onClick={() => {}}
              className="bg-elr-gray text-success rounded-md py-3 px-5"
            />
          )}

          {isModalOpen && renderEditInfo}

          <ElrButtonPreIcon
            text={'Edit Business Info'}
            icon={<PencilIcon fill="#081120" />}
            onClick={() => setIsModalOpen(true)}
            className="bg-elr-white-400 text-elr-black rounded-md py-3 px-5"
          />
          <ElrButtonPreIcon
            text={'Add More Products'}
            icon={<PlusIcon fill="#fff" />}
            onClick={() => setIsUpdateProductModalOpen(true)}
            className="bg-elr-black text-elr-white rounded-md py-3 px-5"
          />
        </div>
      </section>
      <DashboardCards data={cardData} />
    </div>
  );
};

export default MarketDashboard;

interface CardData {
  id?: string;
  title: string;
  count: number | string;
  Icon: React.ReactNode;
}
interface DashboardCardsProps {
  data: CardData[];
}

export const DashboardCards: React.FC<DashboardCardsProps> = ({ data }) => (
  <section className="grid md:grid-cols-column-three grid-cols-column gap-5 mb-5">
    {data.map((card) => (
      <div key={card.title} className="bg-elr-white-400 py-5 px-6">
        <div>
          <div className="rounded-full pb-2 inline-block">{card.Icon}</div>
          <div className="mb-5" />
          <p className="text-lg text-elr-black-300 leading-5 mb-3">
            {card.title}
          </p>
          <p className="text-3xl text-elr-black">{card.count}</p>
        </div>
      </div>
    ))}
  </section>
);

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  loading: boolean;
}

export const SalesTurnOffModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onClick,
  loading,
}) => (
  <ElrModal isOpen={isOpen} onClose={onClose}>
    <div className="flex flex-col items-center text-center py-10 px-6">
      <div className="flex items-center justify-center mb-8">
        <img src={ErrorIcon} alt="error-icon" className="h-20 w-20" />
      </div>
      <p className="text-2xl text-elr-black pb-4 w-10/12">
        You are about to deactivate this sales
      </p>
      <p className="text-base text-center text-elr-black-400 w-11/12">
        This sales will be removed from the sales platform and the sales tickets
        will no longer be sold.
      </p>
      <p className="text-base text-center text-elr-black-400 pt-4">
        Do you still want to proceed?
      </p>
      <div className="flex gap-2.5 mt-10 w-full">
        <ElrButton
          text="Yes, Deactivate It"
          className="py-2 px-4 w-full bg-elr-gray text-elr-black h-10 rounded-md"
          spinnerColor="Black"
          onClick={onClick}
          loading={loading}
        />
        <ElrButton
          text="No, Go Back"
          className="bg-elr-black text-white w-full py-2 px-4 h-10 rounded-md"
          onClick={onClose}
        />
      </div>
    </div>
  </ElrModal>
);

interface UpdateProductProps {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  loading: boolean;
}

export const UpdateProductModal: React.FC<UpdateProductProps> = ({
  isOpen,
  onClose,
  onClick,
  loading,
}) => (
  <ElrModal isOpen={isOpen} onClose={onClose}>
    <div className="flex flex-col items-center text-center py-10 px-6">
      <div className="flex items-center justify-center mb-8">
        <img src={CSVIcon} alt="error-icon" className="h-20 w-20" />
      </div>
      <p className="text-2xl text-elr-black pb-4 w-10/12">Update Products</p>
      <p className="text-base text-center text-elr-black-400 w-11/12">
        Update your products by making edits to the appropriate columns in the
        product file.
      </p>
      <p className="text-base text-center text-elr-black-400 pt-4">
        Download the current CSV to make updates.
      </p>
      <div className="flex gap-2.5 mt-10 w-full">
        <ElrButton
          text="Cancel"
          className="py-2 px-4 w-full bg-elr-gray text-elr-black h-10 rounded-md"
          spinnerColor="Black"
          onClick={onClose}
        />
        <ElrButton
          text="Download CSV"
          className="bg-elr-black text-white w-full py-2 px-4 h-10 rounded-md"
          onClick={onClick}
          loading={loading}
        />
      </div>
    </div>
  </ElrModal>
);
