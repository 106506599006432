import ProfileNavigationMenu from 'app/operator/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/operator/components/menu/TopNavigationMenu';
import { ElrPageTitle } from 'ui-components';
import AccountDetails from './AccountDetails';
import BusinessDeliveryLink from './BusinessDeliveryLink';
import ChangePassword from './ChangePassword';

const Settings = () => {
  const handleChangePassowrd = () => {};
  return (
    <div>
      <ElrPageTitle title="Errandlr - Settings" />
      <ProfileNavigationMenu>
        <div className="flex justify-between w-full md:mx-auto items-center">
          <TopNavigationMenu containerClass="md:mr-0" />
        </div>
        <div className="bg-elr-gray flex flex-col py-8 px-7 md:pt-3 md:px-20">
          <div className="mb-6 text-elr-black font-normal font-serif leading-tight">
            <h3 className="pt-6 text-xl mb-2">Profile Settings</h3>
            <p className="text-elr-black-300 text-base">
              Manage your preferences and security
            </p>
          </div>
          <div className="md:w-10/12 w-full flex flex-col gap-5 md:justify-start">
            <AccountDetails />
            <BusinessDeliveryLink />
            <ChangePassword handleChangePassowrd={handleChangePassowrd} />
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default Settings;
