import NearMeIcon from '@material-ui/icons/NearMe';
import { LocationTagValues } from 'app/utilities/types/shared';
import React from 'react';
import {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  GroupBase,
  NoticeProps,
  Props,
  SingleValueProps,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import { searchLocation } from './utils';

const ClearIndicator = ({
  children,
  ...props
}: ClearIndicatorProps<any, any>) => {
  const {
    // @ts-ignore
    selectProps: { value, origin, estimate },
  } = props;
  return (
    <div className="flex items-center">
      {value && origin && estimate && (
        <div className="flex items-center px-1 bg-white rounded-full">
          <div className="w-1.5 h-1.5 bg-elr-yellow" />
          <div className=" p-0.5 text-xs px-1 opacity-60">
            {estimate.distance || '-'}km
          </div>
        </div>
      )}

      <components.ClearIndicator {...props}>
        {children}
      </components.ClearIndicator>
    </div>
  );
};

const IndicatorSeparator = () => null;

const DropdownIndicator = (
  props: JSX.IntrinsicAttributes &
    DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
) => {
  const { hasValue } = props;
  if (hasValue) {
    return null;
  }

  return (
    <components.DropdownIndicator {...props}>
      <div className="text-sm">
        <NearMeIcon fontSize="inherit" />
      </div>
    </components.DropdownIndicator>
  );
};

const SingleValue = ({ children, ...props }: SingleValueProps<any, any>) => {
  const {
    // @ts-ignore
    selectProps: { isDestination },
  } = props;

  return (
    <components.SingleValue {...props}>
      <div className="">
        <div
          className={`w-1.5 h-1.5 z-20 top-4 left-2.5 absolute ${
            isDestination ? 'bg-elr-yellow' : 'bg-elr-purple rounded-full'
          }`}
        />
        <div className="pl-4 opacity-60 text-elr-black">{children}</div>
      </div>
    </components.SingleValue>
  );
};

const NoOptionsMessage = (props: NoticeProps) => {
  const {
    selectProps: { inputValue },
  } = props;
  if (inputValue.length >= 1) {
    return (
      <components.NoOptionsMessage {...props}>
        Keep Typing...
      </components.NoOptionsMessage>
    );
  }
  return (
    <components.NoOptionsMessage {...props}>
      Start Typing
    </components.NoOptionsMessage>
  );
};

interface LocationProps {
  currentValue?: string;
  isDestination?: boolean;
  origin?: string;
  estimate?: LocationTagValues;
  controlHeight?: number;
  bgColor?: string;
}

export const ElrLocationSelect: React.FC<Props & LocationProps> = ({
  className,
  currentValue,
  isDestination = false,
  controlHeight = 50,
  bgColor = '#F7F7F7',
  ...other
}) => {
  const value = currentValue
    ? { label: currentValue, value: currentValue }
    : null;

  const labelID = `label-for-${other.placeholder}`;

  return (
    <>
      <label className="hidden" id={labelID}>
        {other.placeholder}
      </label>
      <AsyncSelect
        {...other}
        // @ts-ignore
        isDestination={isDestination}
        value={value}
        className={`${className} mb-4`}
        isClearable
        aria-labelledby={labelID}
        cacheOptions
        loadOptions={searchLocation}
        components={{
          IndicatorSeparator,
          DropdownIndicator,
          SingleValue,
          ClearIndicator,
          NoOptionsMessage,
        }}
        styles={{
          container: (base) => ({
            ...base,
            backgroundColor: bgColor,
          }),
          control: (base) => ({
            ...base,
            backgroundColor: bgColor,
            border: 'none',
            boxShadow: 'none',
            height: controlHeight,
          }),
          menu: (base) => ({
            ...base,
            backgroundColor: bgColor,
            border: 'none',
            boxShadow: 'none',
            marginTop: 0,
            borderTop: '0.5px solid rgba(196, 196, 196, 0.3)',
          }),
          option: (base) => ({
            ...base,
            color: '#081120',
            backgroundColor: '#F7F7F7',
            ':hover': { backgroundColor: '#F1F1F1' },
            textAlign: 'left',
          }),
          placeholder: (base) => ({
            ...base,
            color: '#999',
          }),
        }}
      />
    </>
  );
};
