import {
  httpFetchOperatorBatches,
  httpGetNewCommerceBatches,
} from 'app/api/batch';
import {
  httpGetDeliveriesMade as httpGetCommerceDeliveriesMade,
  httpGetUpcomingDeliveries as httpGetCommerceUpcomingDeliveries,
  httpGetProductsList,
} from 'app/api/commerce';
import { RequestsDataInterface, RequestsInterface } from 'app/api/d';
import {
  httpGetDeliveriesMade,
  httpGetUpcomingDeliveries,
} from 'app/api/deliveries';
import { httpGetFinancialIncome } from 'app/api/financial';
import {
  httpGetCommerceRequests,
  httpGetNewProfileRequests,
  httpGetNewRequests,
} from 'app/api/requests';
import {
  useFetchInfiniteQuery,
  useRefetchQuery,
} from 'app/components/dashboard/hooks';
import {
  COMMERCE_DELIVERIES_MADE,
  DISPATCH_DELIVERIES_MADE,
  NEW_BATCH_REQUESTS,
  NEW_COMMERCE_BATCH_REQUESTS,
  NEW_COMMERCE_SINGLE_REQUESTS,
  NEW_SINGLE_REQUESTS,
  UPCOMING_BATCH_REQUESTS,
  UPCOMING_COMMERCE_BATCH_REQUESTS,
  UPCOMING_COMMERCE_SINGLE_REQUESTS,
  UPCOMING_SINGLE_REQUESTS,
} from 'app/state/constants';
import {
  getDynamicRequestsData,
  isCommerce,
  isDispatcher,
} from 'app/utilities/helpers';
import { ProductsListData, RequestType } from 'app/utilities/types/shared';
import { useCallback, useEffect, useState } from 'react';
import { Roles, useGetProfile } from '../user';

export class useFetchError extends Error {}

type GetFinancialIncomeHook = () => [
  (
    userId: string,
    setFinancialIncomeCb: (arg0: number) => void,
    currentAccessRole: Roles
  ) => Promise<void>,
  string | null,
];

export const useGetFinancialIncome: GetFinancialIncomeHook = () => {
  const [error, setError] = useState<string | null>(null);

  const getFinancialIncome = useCallback(
    async (
      userId: string,
      setFinancialIncomeCb: (arg0: number) => void,
      currentAccessRole: Roles
    ) => {
      setError(null);

      try {
        const result = await httpGetFinancialIncome(userId, currentAccessRole);

        setFinancialIncomeCb(
          (result?.balance.income || 0) + (result?.balance.ledger || 0)
        );
      } catch (err) {
        /** no op */
      }
    },
    []
  );

  return [getFinancialIncome, error];
};

type FetchRequestsProfileHook = () => [
  (
    nextPage: number,
    userId: string,
    setNewRequestDataCb: (
      arg0: (_: RequestsInterface) => { requests: RequestsDataInterface }
    ) => void,
    currentAccessRole: Roles
  ) => Promise<void>,
  string | null,
];

export const useFetchProfileRequests: FetchRequestsProfileHook = () => {
  const [error, setError] = useState<string | null>(null);

  const fetchNewRequests = useCallback(
    async (
      nextPage: number,
      userId: string,
      setNewRequestDataCb: (
        arg0: (_: RequestsInterface) => { requests: RequestsDataInterface }
      ) => void,
      currentAccessRole: Roles
    ): Promise<void> => {
      setError(null);
      try {
        const response = await httpGetNewProfileRequests({
          userId,
          nextPage,
          roleId: currentAccessRole,
        });
        setNewRequestDataCb((prevState: RequestsInterface) => {
          const dynamicRequests = getDynamicRequestsData(
            prevState.requests.docs,
            response
          );
          return {
            requests: dynamicRequests,
          };
        });
      } catch (err) {
        // no op
      }
    },
    []
  );

  return [fetchNewRequests, error];
};

// infinite query hooks
export const useFetchNewRequests = (type?: RequestType) => {
  const {
    role,
    currentAccessRole,
    id: userId,
    accountInformation: { id: roleId },
  } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const switchNewRequestTag: any = {
    commerce: NEW_COMMERCE_SINGLE_REQUESTS,
    dispatcher: NEW_SINGLE_REQUESTS,
  };
  const requestTag: string = switchNewRequestTag[currentRole];
  let dt;
  if (isCommerce(currentRole)) {
    dt = 0;
  } else if (isDispatcher(currentRole)) {
    dt = 1;
  } else {
    dt = 2;
  }

  const struct: {
    [key: number]: {
      fn: Function;
      payload: {
        roleId?: string;
        userId: string;
      };
    };
  } = {
    0: {
      fn: httpGetCommerceRequests,
      payload: {
        userId,
        roleId,
      },
    },
    1: {
      fn: httpGetNewRequests,
      payload: {
        userId,
      },
    },
    2: {
      fn: httpGetNewProfileRequests,
      payload: {
        userId,
        roleId: currentRole,
      },
    },
  };

  return useFetchInfiniteQuery({
    key: requestTag,
    ...struct[dt],
    type,
  });
};

export const useFetchUpcomingRequests = (type?: string) => {
  const {
    role,
    id: userId,
    currentAccessRole,
    accountInformation: { id: dispatcherId },
  } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const switchNewRequestTag: any = {
    commerce: UPCOMING_COMMERCE_SINGLE_REQUESTS,
    dispatcher: UPCOMING_SINGLE_REQUESTS,
  };
  const requestTag: string = switchNewRequestTag[currentRole];

  let dt;
  if (isCommerce(currentRole)) {
    dt = 0;
  } else if (isDispatcher(currentRole)) {
    dt = 1;
  } else {
    dt = 2;
  }

  const struct: {
    [key: number]: {
      fn: Function;
      payload: {
        dispatcherId?: string;
        userId: string;
      };
    };
  } = {
    0: {
      fn: httpGetCommerceUpcomingDeliveries,
      payload: {
        userId,
      },
    },
    1: {
      fn: httpGetUpcomingDeliveries,
      payload: {
        userId,
        dispatcherId,
      },
    },
  };

  return useFetchInfiniteQuery({
    key: requestTag,
    ...struct[dt],
    type,
  });
};

/**
 * This function only works for commerce, dispatcher and operator roles.
 * But not for a sender.
 * @param batchType
 * @returns
 */
export const useFetchNewBatchRequests = (batchType: string) => {
  const { role, id: userId, currentAccessRole } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const switchNewRequestTag: any = {
    commerce: NEW_COMMERCE_BATCH_REQUESTS,
    dispatcher: NEW_BATCH_REQUESTS,
  };
  const requestTag: string = switchNewRequestTag[currentRole];

  let dt: number | undefined;
  if (isCommerce(currentRole)) {
    dt = 0;
  } else {
    dt = 1;
  }

  const struct: {
    [key: string]: {
      fn: Function;
      payload: {
        roleId?: string;
        userId: string;
        dispatcherId?: string;
        batchType?: string;
      };
    };
  } = {
    0: {
      fn: httpGetNewCommerceBatches,
      payload: {
        userId,
      },
    },
    1: {
      fn: httpFetchOperatorBatches,
      payload: {
        userId,
      },
    },
  };

  return useFetchInfiniteQuery({
    key: requestTag,
    ...struct[dt],
    type: batchType,
  });
};

export const useFetchDeliveriesMade = (type: string = '') => {
  const {
    id: userId,
    currentAccessRole,
    role,
    accountInformation: { id: dispatcherId },
  } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const switchNewRequestTag: any = {
    commerce: COMMERCE_DELIVERIES_MADE,
    dispatcher: DISPATCH_DELIVERIES_MADE,
  };
  const requestTag: string = switchNewRequestTag[currentRole];
  let dt;
  if (isCommerce(currentRole)) {
    dt = 0;
  } else {
    dt = 1;
  }

  const struct: {
    [key: number]: {
      fn: Function;
      payload: {
        roleId?: string;
        userId: string;
        dispatcherId?: string;
      };
    };
  } = {
    0: {
      fn: httpGetCommerceDeliveriesMade,
      payload: {
        userId,
      },
    },
    1: {
      fn: httpGetDeliveriesMade,
      payload: {
        userId,
        dispatcherId,
      },
    },
  };

  return useFetchInfiniteQuery({
    key: requestTag,
    ...struct[dt],
    type,
  });
};

// refetch query hook
export const useRefetchUpcomingRequests = () => {
  const { role, currentAccessRole } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const switchNewRequestTag: any = {
    commerce: UPCOMING_COMMERCE_SINGLE_REQUESTS,
    dispatcher: UPCOMING_SINGLE_REQUESTS,
  };
  const requestTag: string = switchNewRequestTag[currentRole];

  return useRefetchQuery([requestTag]);
};

export const useRefetchNewRequests = () => {
  const { role, currentAccessRole } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const switchNewRequestTag: any = {
    commerce: NEW_COMMERCE_SINGLE_REQUESTS,
    dispatcher: NEW_SINGLE_REQUESTS,
  };
  const requestTag: string = switchNewRequestTag[currentRole];

  return useRefetchQuery([requestTag]);
};

export const useRefetchDeliveriesMade = () => {
  const { role, currentAccessRole } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const switchNewRequestTag: any = {
    commerce: COMMERCE_DELIVERIES_MADE,
    dispatcher: DISPATCH_DELIVERIES_MADE,
  };
  const requestTag: string = switchNewRequestTag[currentRole];

  return useRefetchQuery([requestTag]);
};

export const useRefetchNewBatchRequests = () => {
  const { role, currentAccessRole } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const switchNewRequestTag: any = {
    commerce: NEW_COMMERCE_BATCH_REQUESTS,
    dispatcher: NEW_BATCH_REQUESTS,
  };
  const requestTag: string = switchNewRequestTag[currentRole];

  return useRefetchQuery([requestTag]);
};

export const useRefetchUpcomingBatchRequests = () => {
  const { role, currentAccessRole } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const switchNewRequestTag: any = {
    commerce: UPCOMING_COMMERCE_BATCH_REQUESTS,
    dispatcher: UPCOMING_BATCH_REQUESTS,
  };
  const requestTag: string = switchNewRequestTag[currentRole];

  return useRefetchQuery([requestTag]);
};

export const useFetchProductsList = () => {
  const [productsListData, setProductsListData] = useState<
    ProductsListData | undefined
  >();

  const {
    id: userId,
    accountInformation: { id: roleId },
  } = useGetProfile();

  const fetchProductList = useCallback(
    async (nextPage: number) => {
      try {
        const result = await httpGetProductsList({
          userId,
          roleId,
          nextPage,
        });
        setProductsListData(result);
      } catch (err: any) {
        /** no op */
      }
    },
    [userId]
  );

  useEffect(() => {
    fetchProductList(1);
  }, [fetchProductList]);

  return { ...productsListData, fetchProductList };
};
