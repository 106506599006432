import { Roles } from 'app/hooks/user';

export interface Company {
  company: string;
}
export interface DispatchRoutes extends Company {
  payment: string;
  logistics: string;
  market: string;
  product: string;
  restaurant: string;
  essentials: string;
  identityVerification: string;
  profile: string;
  requirements: string;
  agents: string;
  settings: string;
  bulkEstimate: string;
}

export interface CommerceRoutes extends Company {
  developer: string;
  commerceSettings: string;
  ecommerce: string;
  logistics: string;
  marketRuns: string;
  marketDashboard: string;
  events: string;
  eventsList: string;
  eventsPreview: string;
  eventsDashboard: string;
  eventsEdit: string;
}

export interface PrivateRoute extends DispatchRoutes, CommerceRoutes {
  dashboard: (access: Roles) => `/${typeof access}/dashboard`;
  onboarding: (access: Roles) => `/${typeof access}/onboarding`;
  billingRecord: string;
  selectPortal: string;
  personalSupport: string;
}

export interface PublicRoute {
  dispatchDetails: string;
  registration: string;
  login: string;
  estimate: string;
  rate: string;
  track: string;
  eventPage: string;
  trackParcelUpdate: string;
  issues: string;
  reviewDesign: string;
  situation: string;
  cancelOrder: string;
  confirmCancel: string;
  tradeAddress: string;
  termsAndCondition: string;
  riderTermsAndCondition: string;
  privacyPolicy: string;
  policy: string;
  billing: string;
  verifyBilling: string;
  failedBilling: string;
  resetPassword: string;
  forgotPassword: string;
  confirmDetails: string;
  faq: string;
  landing: string;
  contact: string;
  sender: string;
  commerce: string;
  dispatcher: string;
  bankinfo: string;
  cookiePolicy: string;
  aboutUs: string;
  deliveryPolicy: string;
  careers: string;
  apiPage: string;
  sla: string;
  customerEstimate: string;
  paymentGatewayResponse: string;
  event: string;
  eventQrScan: string;
}

const company = 'company';
export const dispatcherRoutes: DispatchRoutes = {
  payment: 'payment',
  profile: 'profile',
  logistics: 'logistics',
  market: 'market',
  product: 'product',
  restaurant: 'restaurant',
  essentials: 'essentials',
  identityVerification: 'identity-verification',
  company,
  requirements: '/dispatcher/requirements',
  agents: 'agents',
  settings: 'settings',
  bulkEstimate: 'bulk-estimate',
};

export const commerceRoutes: CommerceRoutes = {
  developer: 'developer',
  company,
  commerceSettings: 'settings',
  ecommerce: 'ecommerce',
  logistics: 'logistics',
  marketRuns: 'marketRuns',
  marketDashboard: 'marketDashboard',
  events: 'events',
  eventsList: 'eventsList',
  eventsPreview: 'eventsPreview',
  eventsDashboard: 'eventsDashboard',
  eventsEdit: 'eventsEdit',
};

export const privateRoutes: PrivateRoute = {
  ...dispatcherRoutes,
  ...commerceRoutes,
  dashboard: (access) => `/${access}/dashboard`,
  onboarding: (access) => `/${access}/onboarding`,
  billingRecord: 'billing-record',
  selectPortal: '/select-portal',
  personalSupport: '/premium-support',
};

export type FetchProfileOnRouteType = PrivateRoute & {
  estimate: string;
};
export const fetchProfileOnRoutes: FetchProfileOnRouteType = {
  ...privateRoutes,
  estimate: '/estimate',
};

export const publicRoutes: PublicRoute = {
  dispatchDetails: '/dispatch-details',
  registration: '/register',
  login: '/login',
  estimate: '/estimate',
  rate: '/rate',
  careers: '/careers',
  track: '/track',
  eventPage: '/event',
  trackParcelUpdate: '/track-parcel-update',
  issues: '/issues',
  reviewDesign: '/review-design',
  situation: '/situation',
  cancelOrder: '/cancel-order',
  confirmCancel: '/confirm-cancel',
  tradeAddress: '/trade-address',
  policy: '/policy',
  termsAndCondition: '/policy/terms-and-condition',
  riderTermsAndCondition: '/policy/rider-terms-and-condition',
  privacyPolicy: '/policy/privacy-policy',
  faq: '/policy/faq',
  billing: '/billing',
  verifyBilling: '/billing/verify',
  failedBilling: '/billing/failed',
  resetPassword: '/reset-password',
  forgotPassword: '/forgot-password',
  confirmDetails: '/confirm-details',
  landing: '/',
  contact: '/contact',
  sender: '/sender',
  commerce: '/commerce',
  dispatcher: '/dispatcher',
  bankinfo: '/bankinfo',
  cookiePolicy: '/cookie-policy',
  aboutUs: '/about-us',
  deliveryPolicy: '/delivery-policy',
  apiPage: '/developers',
  sla: '/customer-terms/sla',
  customerEstimate: '/mv',
  paymentGatewayResponse: '/payment-gateway-transaction-response',
  event: '/evt',
  eventQrScan: '/evt-scan',
};
