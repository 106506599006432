import CountElement from 'app/components/CountElement';
import { useGetProfile } from 'app/hooks/user';
import { CommerceView } from 'app/utilities/ComponentView';
import { isDispatcherOnly } from 'app/utilities/helpers';
import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import React from 'react';
import { Link } from 'react-router-dom';
import * as styles from '../../dispatcher/modules/components/ClassUtils';
import {
  DeliveriesMadeLabel,
  NewRequestLabel,
  UpcomingDeliveriesLabel,
} from './PanelLabels';

interface Props {
  panelActive: number;
  onClick: Function;
  totalDocs: {
    newRequestsTotal?: number;
    upcomingRequestsTotal?: number;
    deliveriesMadeTotal?: number;
    productsListTotal?: number;
  };
}

const styl = {
  boxContainer:
    'flex md:block overflow-x-scroll no-scrollbar md:overflow-x-visible',
  activeClass: 'w-40 h-24',
  boxOne: 'ml-0 mb-2.5 md:mb-5',
  boxTwo: 'mb-2.5 md:mb-5',
  boxThree: 'ml-5 mb-2.5 md:ml-0',
  segmentContainer: 'md:justify-center',
  hideExpand: 'hidden md:block',
  numContainer: 'mt-2 md:mt-5',
};

const NewRequest = ({
  onClick,
  panelActive,
  totalDocs,
}: Pick<Props, 'panelActive' | 'onClick'> & {
  totalDocs: number;
}) => {
  const panelNumber = 1;
  const isActivePanel = panelActive === panelNumber;
  return (
    <div
      onClick={() => onClick(1)}
      className={styles.newRequestContainer(panelActive, isActivePanel, styl)}
    >
      <NewRequestLabel />
      <div className={styles.newRequestNumContainer(panelActive, styl)}>
        <div className="md:hidden">
          <CountElement bgColor="elr-yellow" size={panelActive ? 'sm' : 'md'}>
            {totalDocs}
          </CountElement>
        </div>

        <div className="hidden md:block">
          <CountElement bgColor="elr-yellow" size="md">
            {totalDocs}
          </CountElement>
        </div>
      </div>

      <div className={styles.newRequestExpandAction(panelActive, styl)}>
        Expand
      </div>
    </div>
  );
};

const UpcomingDeliveries = ({
  onClick,
  panelActive,
  totalDocs,
}: Pick<Props, 'panelActive' | 'onClick'> & {
  totalDocs: number;
}) => {
  const panelNumber = 2;
  const isActivePanel = panelActive === panelNumber;
  return (
    <div
      onClick={() => onClick(2)}
      className={styles.upcomingContainer(panelActive, isActivePanel, styl)}
    >
      <UpcomingDeliveriesLabel />
      <div className={styles.upcomingNumContainer(panelActive, styl)}>
        <div className="md:hidden">
          <CountElement bgColor="elr-purple" size={panelActive ? 'sm' : 'md'}>
            {totalDocs}
          </CountElement>
        </div>

        <div className="hidden md:block">
          <CountElement bgColor="elr-purple" size="md">
            {totalDocs}
          </CountElement>
        </div>
      </div>
      <div className={styles.upcomingExpandAction(panelActive, styl)}>
        Expand
      </div>
    </div>
  );
};

const DeliveriesMade = ({
  onClick,
  panelActive,
  totalDocs,
}: Pick<Props, 'panelActive' | 'onClick'> & {
  totalDocs: number;
}) => {
  const panelNumber = 3;
  const isActivePanel = panelActive === panelNumber;

  return (
    <div
      onClick={() => onClick(3)}
      className={styles.deliveriesContainer(panelActive, isActivePanel, styl)}
    >
      <DeliveriesMadeLabel />
      <div className={styles.deliveriesNumContainer(panelActive)}>
        <div className="md:hidden">
          <CountElement bgColor="elr-green" size={panelActive ? 'sm' : 'md'}>
            {totalDocs}
          </CountElement>
        </div>

        <div className="hidden md:block">
          <CountElement bgColor="elr-green" size="md">
            {totalDocs}
          </CountElement>
        </div>
      </div>
      <div className={styles.deliveriesExpandAction(panelActive, styl)}>
        Expand
      </div>
    </div>
  );
};

export const CurrentBatch = ({
  onClick,
  panelActive,
  totalDocs,
}: Pick<Props, 'panelActive' | 'onClick'> & { totalDocs: number }) => {
  const panelNumber = 1;
  const isActivePanel = panelActive === panelNumber;

  return (
    <div
      onClick={() => onClick(1)}
      className={styles.newRequestContainer(panelActive, isActivePanel, styl)}
    >
      <div className="flex items-center justify-between">
        <p className="text-sm md:text-lg text-elr-black md:text-elr-black text-opacity-60">
          Batch
        </p>
      </div>
      <div className={styles.newRequestNumContainer(panelActive, styl)}>
        <p className={styles.newRequestTotalCountElem(panelActive)}>
          <span className="text-xl md:text-30">{totalDocs}</span>
        </p>
      </div>

      <div className={styles.newRequestExpandAction(panelActive, styl)}>
        Expand
      </div>
    </div>
  );
};

const InTransit = ({
  onClick,
  panelActive,
  totalDocs,
}: Pick<Props, 'panelActive' | 'onClick'> & { totalDocs: number }) => {
  const panelNumber = 2;
  const { currentAccessRole, access } = useGetProfile();
  const isActivePanel = panelActive === panelNumber;

  const panelText = isDispatcherOnly(access, currentAccessRole)
    ? 'In-Transit'
    : 'Upcoming Deliveries';

  return (
    <div
      onClick={() => onClick(2)}
      className={styles.upcomingContainer(panelActive, isActivePanel, styl)}
    >
      <div className="flex justify-between">
        <div className="text-xs md:text-lg text-elr-black md:text-elr-black text-opacity-60">
          {panelText}
        </div>
      </div>
      <div className={styles.upcomingNumContainer(panelActive, styl)}>
        <CountElement bgColor="elr-purple" size="md">
          {totalDocs}
        </CountElement>
      </div>

      <div className={styles.upcomingExpandAction(panelActive, styl)}>
        Expand
      </div>
    </div>
  );
};

const RenderBusinessComerceLink = () => {
  const { currentAccessRole } = useGetProfile();

  return (
    <CommerceView role={currentAccessRole}>
      <div
        className={`border border-elr-green border-opacity-30 bg-elr-white-400 rounded-md
    md:mt-2 mt-4 mb-2 md:flex items-center p-2 md:justify-between`}
      >
        <div className="text-elr-black text-lg">
          <p className="pt-1">Business Delivery Link</p>
        </div>
        <div className="flex justify-between text-opacity-50 border-b-2 p-2 border-elr-black bg-elr-gray-600 bg-opacity-60 ">
          <p className="text-elr-black text-opacity-60 mr-2">
            <Link to={`/${COMMERCE}/${privateRoutes.company}`}>View Here</Link>
          </p>
        </div>
      </div>
    </CommerceView>
  );
};

const DashboardPanel: React.FC<Props> = ({
  onClick,
  panelActive,
  totalDocs,
}) => {
  const { currentAccessRole } = useGetProfile();

  const topTwoTabConfig = () => {
    switch (currentAccessRole) {
      case COMMERCE:
        return (
          <>
            <NewRequest
              onClick={onClick}
              totalDocs={totalDocs.newRequestsTotal || 0}
              panelActive={panelActive}
            />
            <InTransit
              onClick={onClick}
              totalDocs={totalDocs.upcomingRequestsTotal || 0}
              panelActive={panelActive}
            />
          </>
        );
      default:
        return (
          <>
            <NewRequest
              onClick={onClick}
              totalDocs={totalDocs.newRequestsTotal || 0}
              panelActive={panelActive}
            />
            <UpcomingDeliveries
              onClick={onClick}
              totalDocs={totalDocs.upcomingRequestsTotal || 0}
              panelActive={panelActive}
            />
          </>
        );
    }
  };

  return (
    <div
      className={`${
        panelActive ? 'mt-2.5 md:mt-20' : 'mt-6 md:mt-20'
      } w-full md:w-[95%]`}
    >
      {currentAccessRole === COMMERCE && (
        <span className="md:hidden">
          <RenderBusinessComerceLink />
        </span>
      )}
      <div className={`${panelActive ? styl.boxContainer : ''}`}>
        <div className={`flex ${panelActive && styl.segmentContainer}`}>
          {topTwoTabConfig()}
        </div>

        <div className={`flex ${panelActive && styl.segmentContainer} mb-4`}>
          <DeliveriesMade
            totalDocs={totalDocs.deliveriesMadeTotal || 0}
            onClick={onClick}
            panelActive={panelActive}
          />
        </div>
      </div>

      <span className="md:block hidden">
        <RenderBusinessComerceLink />
      </span>
    </div>
  );
};

export default DashboardPanel;
