import React from 'react';
import Cancel from 'assets/images/black-cancel.svg';
import { slideInChildrenContainer, slideInContainer } from './ClassUtils';
import { slideWrapperStyle } from '../ClassUtils';

interface Props {
  shown: boolean;
  children: React.ReactNode;
  close: () => void;
  overflow?: boolean;
  leftMarginOffset?: string;
}

const SlideIn: React.FC<Props> = ({
  shown = false,
  children,
  close,
  overflow,
  leftMarginOffset = '',
}) => (
  <div id="slide-container" className={slideInContainer(shown)}>
    <div className={slideWrapperStyle(leftMarginOffset)}>
      <button
        className="absolute md:-left-16 md:top-4 left-6 top-8 w-4 h-4 md:w-12 md:h-12 z-10 bg-white rounded-full mx-auto flex items-center justify-center"
        type="button"
        onClick={close}
      >
        <img src={Cancel} alt="Cancel" />
      </button>
      <div className={slideInChildrenContainer(overflow)}>{children}</div>
    </div>
  </div>
);

export default SlideIn;
