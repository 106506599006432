import MenuIcon from '@material-ui/icons/Menu';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { useLogout } from 'app/hooks/auth';
import { useGetProfile } from 'app/hooks/user';
import {
  canOperateInCountryAndState,
  isDispatcherPlatform,
} from 'app/utilities/helpers';
import mappedNavigation from 'app/utilities/navigations';
import { privateRoutes, publicRoutes } from 'app/utilities/routes';
import Logo from 'assets/images/logo.svg';
import newIcon from 'assets/images/newIcon.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import ProfilePicIcon from '@material-ui/icons/AccountCircleOutlined';
import HamburgerMenuIcon from '@material-ui/icons/MenuOutlined';
import { HamburgerMenu } from './menu/HamburgerMenu';
import { RoleLinkActions } from './menu/TopNavigationMenu';
import { UnmanagedAgentsIcon } from './menu/misc';
import DynamicIconDropDownMenu from './DynamicIconDropDownMenu';

interface Props {
  mdHide?: boolean;
  linkTo?: string;
  showUnassignedAgents?: () => void;
  unmanagedAgentsCount?: number;
  className?: string;
  toggleMenu?: () => void;
}

export const MobileHeader: React.FC<Props> = ({
  mdHide,
  linkTo = '/',
  showUnassignedAgents,
  unmanagedAgentsCount,
  className,
  toggleMenu,
}) => {
  const { id, currentAccessRole, role, accountInformation } = useGetProfile();
  const [modal, setModal] = React.useState(false);
  const [sendLogout] = useLogout();
  const hideOnMedium = mdHide ? 'md:hidden' : '';
  const [showMenu, setShowMenu] = React.useState(false);
  const toggleMobileMenu = () => {
    setShowMenu(!showMenu);
  };
  const currentRole = currentAccessRole || role;
  const navigations = mappedNavigation(currentRole);
  const removeNav = (navigation: { title: string; route: string }) =>
    navigation.title === 'Dashboard' || navigation.title === 'Account';

  const navLinks = [
    { path: publicRoutes.estimate, label: 'Estimate' },
    { path: 'track-parcel', label: 'Track Parcel' },
    { path: 'about-us', label: 'About Us' },
    { path: 'event', label: 'Events' },
    { path: 'api', label: 'API' },
  ];

  return (
    <div
      className={`h-16 md:h-fit bg-white ${hideOnMedium} md:mb-16 p-6 md:px-0 flex !justify-between items-center md:items-start ${className}`}
    >
      <div className="flex items-center md:hidden" onClick={toggleMenu}>
        <HamburgerMenuIcon />
        <p className="ml-2">Menu</p>
      </div>

      <div className="md:block hidden mx-7">
        <Link to={linkTo}>
          <img
            className="h-6 md:h-9"
            src={Logo}
            alt="Errandlr-Logo"
            title="Errandlr-Logo"
          />
        </Link>
      </div>

      {id ? (
        <div className="flex items-center md:hidden">
          {isDispatcherPlatform(currentRole) && (
            <RoleLinkActions
              topClassNames="mr-5"
              sectionClassNames="absolute bg-white left-2 rounded-lg w-52 p-5 mt-2 z-100 shadow-xl"
            />
          )}

          {isDispatcherPlatform(currentRole) &&
          canOperateInCountryAndState(accountInformation.areasOfOperations) ? (
            <UnmanagedAgentsIcon
              onClick={showUnassignedAgents}
              unmanagedAgentsCount={unmanagedAgentsCount}
            />
          ) : null}

          <div className="p-3 bg-elr-gray rounded-full">
            <NotificationsNoneIcon className="text-elr-gray-400" />
          </div>
          <div className="p-3 bg-elr-gray rounded-full mx-4">
            <Link to={privateRoutes.personalSupport}>
              <HelpIcon className="text-elr-gray-400" />
            </Link>
          </div>
          <div
            className="flex justify-center items-center py-1 px-2 bg-elr-gray rounded-full"
            onClick={() => setModal(!modal)}
          >
            <div className="p-2 bg-white rounded-full">
              <ProfilePicIcon className="text-elr-gray-400" />
            </div>
            <DynamicIconDropDownMenu
              containerStyles="border-0.5 border-elr-green bg-white border-opacity-40 z-100"
              transformPositionClass="-translate-x-24"
            >
              <ul className="list-none bg-white text-elr-black border rounded border-elr-green border-opacity-30 text-opacity-40 text-sm">
                {navigations.filter(removeNav).map((navigation) => (
                  <li key={navigation.route}>
                    <Link to={navigation.route}>
                      <div className="cursor-pointer px-1 m-2 w-24 leading-7 hover:bg-elr-green hover:bg-opacity-10">
                        {navigation.title}
                      </div>
                    </Link>
                  </li>
                ))}
                <li>
                  <Link to={`/${currentRole}/${privateRoutes.bulkEstimate}`}>
                    <div className="cursor-pointer px-1 m-2 w-24 leading-7 flex hover:bg-elr-green hover:bg-opacity-10">
                      Bulk Order
                      <span>
                        <img src={newIcon} alt="new icon" />
                      </span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to={privateRoutes.selectPortal}>
                    <div className="cursor-pointer px-1 m-2 w-24 leading-7 hover:bg-elr-green hover:bg-opacity-10">
                      Select Portal
                    </div>
                  </Link>
                </li>
                <li>
                  <div
                    onClick={sendLogout}
                    className="cursor-pointer px-1 m-2 w-24 leading-7 hover:bg-elr-green hover:bg-opacity-10"
                  >
                    Logout
                  </div>
                </li>
              </ul>
            </DynamicIconDropDownMenu>
          </div>
        </div>
      ) : (
        <div className="block md:hidden">
          <MenuIcon onClick={toggleMobileMenu} />
          {showMenu && (
            <div>
              <HamburgerMenu
                navLinks={navLinks}
                onClose={toggleMobileMenu}
                topPositionClassName="top-3"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

interface CenterLogoProps {
  className?: string;
  slideInMode?: boolean;
}

export const CenterLogoHeader: React.FC<CenterLogoProps> = ({
  className = '',
  slideInMode,
}) => (
  <div
    className={`${className} mb-16 mt-20 ${slideInMode ? 'hidden' : 'block'}`}
  >
    <Link to="/">
      <img src={Logo} alt="Errandlr-Logo" title="Errandlr-Logo" />
    </Link>
  </div>
);
