import ProfileNavigationMenu from 'app/operator/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/operator/components/menu/TopNavigationMenu';
import { ElrPageTitle } from 'ui-components';

const index = () => (
  <div>
    <ElrPageTitle title="Errandlr - Identity Verification" />
    <ProfileNavigationMenu>
      <div className="flex justify-between w-full md:mx-auto items-center">
        <TopNavigationMenu containerClass="md:mr-0" />
      </div>
      <div className="bg-elr-gray flex flex-col py-8 px-7 md:pt-3 md:px-20">
        <div className="mb-6 text-elr-black font-normal font-serif leading-tight">
          <h3 className="pt-6 text-xl mb-2">Identity Verification</h3>
          <p className="text-elr-black-300 text-base">
            Verify users’ identity for account upgrade
          </p>
        </div>
      </div>
    </ProfileNavigationMenu>
  </div>
);

export default index;
