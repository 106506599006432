import { RequestTypes } from 'app/utilities/types/shared';

export const slideWrapperStyle = (leftMarginOffset: string) =>
  `${
    leftMarginOffset.trim() ? `${leftMarginOffset}` : 'md:ml-64p'
  } bg-elr-white-400 h-screen px-10 md:px-16 pt-14 md:pt-8 relative overflow-x-visible  no-scrollbar`;

export const dispatcherActionBtnStyle = (isSorted: boolean) =>
  `${
    isSorted ? 'bg-elr-black' : 'bg-elr-purple'
  } h-8 w-40 text-white text-base flex items-center justify-center`;

export const dropdownWrapperStyle = (notLastItem: boolean) =>
  `md:ml-4 ${!notLastItem && 'mt-5'} w-full mb-6 px-3 py-3 md:ml-4 bg-elr-gray`;

export const fulfilmentReturnStatus = (status: string) =>
  status === RequestTypes.assigned
    ? 'Delivery marked to be returned to fulfilment center'
    : 'Delivery marked as returned to fulfilment center';

export const sortingReturnStatus = (status: string) =>
  status === RequestTypes.assigned
    ? 'Delivery marked to be returned to sorting station'
    : 'Delivery marked as returned to sorting station';

export const removeButtonStyle = (disabled: boolean) =>
  `${disabled && 'opacity-60'} flex gap-1 items-center`;

export const removeTextStyle = (parcelStatus: boolean) =>
  `${parcelStatus ? 'text-elr-grey' : 'text-elr-error'} text-10`;

export const activeReasonStyle = (isActive: boolean) =>
  `${
    isActive
      ? 'bg-elr-black text-white'
      : 'text-elr-black text-opacity-60 border border-elr-gray-400 border-opacity-60'
  } text-sm px-4 py-2 mr-3 mb-3 `;

export const walletBalancestyle = (walletBalance: number) => `
${walletBalance < 0 ? 'text-elr-error' : 'text-elr-black'} 
`;
