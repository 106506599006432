import { httpFetchTenants } from 'app/api/requests';
import { ENV } from 'app/constants';
import { Roles } from 'app/hooks/user';
import deliveryBike from 'assets/images/delivery-bike.svg';
import documentIcon from 'assets/images/document.svg';
import foodIcon from 'assets/images/foodIcon.svg';
import { getDistance } from 'geolib';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import {
  isEmpty as RIsEmpty,
  isNil as RIsNil,
  reject as RIsReject,
  curry,
  dissoc,
  either,
  is,
  map,
  omit,
  pipe,
  when,
} from 'ramda';
import { Session, tenantDataKey } from './Session';
import { getCurrentCountryData } from './country';
import {
  BUSINESS,
  COMMERCE,
  DISPATCHER,
  OPERATOR,
  RIDER,
  SENDER,
  SUPPORT,
} from './roles';
import {
  EstimateProps,
  HttpDispatchResponse,
  RequestType,
} from './types/shared';

export const packageTypeIcons = {
  small_parcel: deliveryBike,
  large_parcel: deliveryBike,
  document: documentIcon,
  food: foodIcon,
};

export const getArrLastItem = (arr: {}[]) => arr.indexOf(arr[arr.length - 1]);

export const currencyFormatter = (
  price: number,
  locale: string = 'en-NG',
  currency: string = getCurrentCountryData().currencyCode.toUpperCase()
) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(price);

export const currentYear = new Date().getFullYear();

// Profile image initials
export const initials = (data: string) => data.charAt(0).toUpperCase();
export const companyInitials = (data: string | undefined) =>
  data?.charAt(0).toUpperCase().concat(data[2]).toUpperCase();

type Emp = { [key: string]: any } | any;
export const removeEmptyKeys = (obj: Emp) => {
  const p = obj;
  Object.entries(p).forEach(
    ([key, val]) =>
      (val && typeof val === 'object' && removeEmptyKeys(val)) ||
      ((val === null || val === '') && delete p[key])
  );
  return p;
};

export const getFormatedAdresses = (routes: EstimateProps | undefined) => {
  const pickupLocation = routes?.pickupLocation?.label?.replace(/\s|&/g, (v) =>
    v === ' ,' ? ', ' : '+'
  );
  const dropOffAddresses = routes?.dropoffLocations;
  const dropOffLocations = dropOffAddresses
    ?.map((dest) => dest.destination?.label)
    .map((val) => val.replace(/\s|&/g, (v) => (v === ' ,' ? ', ' : '+')));
  /**
   * This removes all space and illegal characters in this format (Lekki & Phase 1,Lekki, Nigeria) to this format (Lekki+Phase+1,+Lekki,+Nigeria)
   * */

  return { pickupLocation, dropOffLocations };
};

export const isDevelopment = ENV === 'development';

// user role types
export const isDispatcher = (role: string) => role === DISPATCHER;
export const isDispatcherOnly = (access: string[], currentAccessRole: string) =>
  !access.includes(OPERATOR) && currentAccessRole === DISPATCHER;
export const hasOperatorAccess = (access: string[]) =>
  access.includes(OPERATOR);
export const isOperator = (currentAccessRole: string) =>
  currentAccessRole === OPERATOR;
export const isSender = (role: string) => role === SENDER;
export const isBusiness = (role: string) => role === BUSINESS;
export const isRider = (role: string) => role === RIDER;
export const isCommerce = (role: string) => role === COMMERCE;
export const isSupport = (role: string) => role === SUPPORT;

export const checkCompanyProfile = (feildsObj: any) => {
  const emptyVals: Array<string> = [];
  Object.entries(feildsObj).forEach(
    (val) => isEmpty(val[1]) && emptyVals.push(val[0])
  );

  return { isNotEmpty: (emptyVals.length && emptyVals) || false };
};

export const removePropDeepNodes: any = curry((prop: string, o: object) =>
  // @ts-ignore
  when(is(Object), pipe(dissoc(prop), map(removePropDeepNodes(prop))), o)
);

export const omitNodeKey = (
  key: string,
  payload: string | object | undefined
) => {
  // @ts-ignore
  const fn = omit([key], payload);
  return isEmpty(fn) ? null : fn;
};

export const numberFormatterShort = (price: number, decimals: number = 2) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const regEx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((i) => price >= i.value);
  return item
    ? (price / item.value).toFixed(decimals).replace(regEx, '$1') + item.symbol
    : '0';
};

export const setRedirectUrl = (path: typeof window.location.pathname) =>
  `?rdr=${path}`;

export const isSenderPlatform = (url: string, userRole: Roles = DISPATCHER) =>
  url.includes(SENDER) && isSender(userRole);

export const isCommercePlatform = (url: string, userRole: Roles = DISPATCHER) =>
  url.includes(COMMERCE) && isCommerce(userRole);

export const isDispatcherPlatform = (userRole: Roles) =>
  window.location.pathname.includes(DISPATCHER) && userRole === DISPATCHER;

export const cleanNullInObject: any = (o: { [key: string]: any }) =>
  pipe(
    // @ts-ignore
    RIsReject(either(RIsNil, RIsEmpty)),
    map(when(is(Object), cleanNullInObject))
    // @ts-ignore
  )(o);

export const extractActiveStateTenant = (country = 'nigeria') => {
  const tenantData = Session.tenantData();
  const selectedCountry = tenantData[country];
  const d = Object.entries(selectedCountry.states);
  const filterState = () => d.filter((state) => state[1].active);
  const activeState = filterState();
  return activeState.map((item) => ({ value: item[0], label: item[0] }));
};

export const MARKETPLACE = 'marketplace';
export const MARKETPLACE_SHORT = 'MP';
export const QUOTE_SHORT = 'QP';

export const isMarketPlace = (requestType: RequestType) =>
  requestType === MARKETPLACE;

export const switchRequestType = async (
  requestType: RequestType,
  nextPage: number | undefined,
  fetchRequest: Function
) => {
  await fetchRequest(nextPage, requestType);
};

export const canOperateInCountryAndState = (
  areasOfOperations:
    | {
        country: string;
        states: string[];
      }[]
    | undefined
) => {
  const tenantData = Session.tenantData();
  const countries = Object.keys(tenantData);

  const countriesToOperate = countries.filter(
    (country) => tenantData[country as keyof typeof tenantData].canOperate
  );

  const statesToOperate: string[] = [];

  for (const a of countriesToOperate) {
    const states = Object.entries(
      tenantData[a as keyof typeof tenantData].states
    );
    for (const state of states) {
      if (state[1].canOperate) {
        statesToOperate.push(state[0]);
      }
    }
  }

  if (!areasOfOperations) return false;
  const hasCountry = areasOfOperations.some((area) =>
    countriesToOperate.includes(area.country)
  );
  if (!hasCountry) return false;
  const hasState = areasOfOperations.some((area) =>
    area.states.some((st) => statesToOperate.includes(st))
  );
  return !!hasState;
};

export const isRequestCollected = (status: string) => status === 'collected';
export const isRequestCompleted = (status: string) => status === 'completed';
export const isRequestPaid = (status: string) => status !== 'pre_payment';

export const NEW_REQUEST_QUERY_KEY = 'new_request_query_key';

export const formattedDate = (date: string, format: string) =>
  DateTime.fromISO(date).toFormat(format);
export const formattedTime = (date: string) =>
  DateTime.fromISO(date).toLocaleString({
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h12',
  });

export const statusColors = ['elr-yellow', 'elr-purple', 'elr-green'];

export const dispatchTabs = [
  {
    id: 'single',
    label: 'Single Delivery',
    disabled: false,
  },
  {
    id: 'batch',
    label: 'Batch Delivery',
    disabled: false,
  },
];

export const requestTabs = [
  {
    id: 'successful',
    label: 'Succesful requests',
    disabled: false,
  },
  {
    id: 'pending',
    label: 'Pending Requests',
    disabled: false,
  },
  {
    id: 'cancelled',
    label: 'Cancelled Requests',
    disabled: false,
  },
];

export const BATCH_STAGES = {
  open: 'open',
  fulfilment: 'fulfilment',
};

export const batchRequestTabs: {
  id: string;
  label: string;
  disabled: boolean;
}[] = [
  {
    id: BATCH_STAGES.open,
    label: 'Open',
    disabled: false,
  },
  {
    id: BATCH_STAGES.fulfilment,
    label: 'Fulfilment',
    disabled: false,
  },
];

export const isCurrentTab = (id: string, currentTab?: string) =>
  id === currentTab;
export const isBatchTab = (id: string) => id === 'batch';
export const isSingleTab = (id: string) => id === 'single';

export const getDynamicRequestsData = (prevStateDocs: any, response: any) => {
  const isPaginatedData = response.page > 1;
  const requestDocs = isPaginatedData
    ? [...prevStateDocs, ...response.docs]
    : response.docs;

  return { ...response, docs: requestDocs };
};

export const parseRequestType = (type: string) =>
  type.toLowerCase().split(' ').join('-');

export const parseJwt = (
  token: string
): {
  sub: string;
  iat: string;
  exp: string;
} => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const loadTenantData = async () => {
  const getTenantData = Session.getValue(tenantDataKey);
  if (!getTenantData) {
    const tenant = await httpFetchTenants();
    Session.setValue(tenantDataKey, JSON.stringify(tenant));
    return tenant;
  }
  return getTenantData;
};

export const batchID = (trackingId: string) => trackingId.slice(-4);

export const isDashboard = window.location.pathname.includes('dashboard');

export const requestEstimation = (estimate: HttpDispatchResponse) =>
  estimate.batch ? estimate.batchEstimate : estimate.estimate;

export const RIB_TRACKING_PREFIX = 'RIB_';

export const renderTrackingId = (
  urlTrackingID: string,
  resultTrackingId: string
) => {
  if (urlTrackingID.includes(RIB_TRACKING_PREFIX)) return urlTrackingID;
  return resultTrackingId;
};

export const isBatchOrder = (trackingId: string) =>
  trackingId.startsWith('RIB_');

export const isOrderRIB = (trackingId: string) => trackingId.startsWith('RIB_');

export const trackingBadgeLabel = (trackingId: string, source?: string) => {
  if (isBatchOrder(trackingId)) return 'Economy';
  if (source === 'commerce') return 'Commerce';
  return 'Platform';
};

export const hasEmptyInputs = (values: { [val: string]: any }) =>
  Object.values(values).some((value) => value.trim() === '');

export const roundUpAmount = (amount: number, decimal: number = 2) => {
  if (!amount) return 0;
  return +amount.toFixed(decimal);
};

export const getDistanceinKm = (
  pickupPoint: { latitude: string; longitude: string },
  destination: { latitude: string; longitude: string }
) =>
  pickupPoint.latitude
    ? `${(getDistance(pickupPoint, destination) / 1000)
        .toFixed()
        .toString()} km`
    : '';

export const isEcoBatch = (currentRole: Roles, isAnOperator: boolean) =>
  (isSender(currentRole) || isCommerce(currentRole)) && !isAnOperator;

export const DEFAULT_COUNTRY = 'nigeria';

export const getNonEmptyValues = (obj: any) => {
  const result: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (
        value &&
        ((typeof value === 'string' && value.trim() !== '') ||
          value === true ||
          (typeof value === 'number' && value > 0) ||
          value.length > 0)
      ) {
        result[key] = value;
      }
    }
  }

  return result;
};

export const generateMapUrl = (
  pickupLocation: { latitude: string; longitude: string },
  destination: { latitude: string; longitude: string }
) =>
  pickupLocation.latitude && pickupLocation.longitude
    ? `https://www.google.com/maps/dir/?api=1&origin=${pickupLocation.latitude}%2C${pickupLocation.longitude}&destination=${destination.latitude}%2C${destination.longitude}&travelmode=driving`
    : `https://www.google.com/maps/search/?api=1&query=${destination.latitude}%2C${destination.longitude}`;

// https://support.paystack.com/hc/en-us/articles/360009973579-Can-I-pass-the-transaction-charges-to-my-customers
export const calculatePaymentFee = (amountInNaira: number): number => {
  // Final Amount = (({Price} + {Flat Fee}) / (1 - {Decimal Fee})) + 0.01
  const finalFee = Math.round(
    Math.ceil((amountInNaira + 100) / (1 - 0.015)) + 0.01
  );
  const checkLimit = Math.round(finalFee - amountInNaira);
  if (checkLimit > 2000) {
    return amountInNaira + 2000;
  }
  return finalFee;
};

export const calculatePaymentFeeV2 = (
  amountInNaira: number
): { finalFee: number; paymentFee: number } => {
  // Final Amount = (({Price} + {Flat Fee}) / (1 - {Decimal Fee})) + 0.01
  const finalFee = Math.round(
    Math.ceil((amountInNaira + 100) / (1 - 0.015)) + 0.01
  );
  const paymentFee = Math.round(finalFee - amountInNaira);

  if (paymentFee >= 2000) {
    return { finalFee: amountInNaira + 2000, paymentFee: 2000 };
  }
  return { finalFee, paymentFee };
};

export const fileSize = (size: number) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2)} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
};

export const getFirstItem = <T>(array: T[]): T => array[0];
