import { GOOGLE_API_KEY } from 'app/constants';
import SupportHeader from 'app/utilities/HttpSupportHeader';
import { axiosDefault as axios, axiosStatic } from 'app/utilities/axios';
import {
  GeoLocationData,
  HttpDispatchResponse,
  HttpLocationValues,
} from 'app/utilities/types/shared';
import memoizeOne from 'memoize-one';
import { toast } from 'react-toastify';
import { SendRequest } from './d';
import { AddBeneficiary, GeoDataResponse } from './types';
import { callApi } from './utils';

export const httpGetEstimate = ({
  pickupLocation,
  dropoffLocations,
  parcelCode,
}: HttpLocationValues): Promise<HttpDispatchResponse> => {
  const mapUrl = `pickupLocation=${JSON.stringify({
    id: `place_id:${pickupLocation.id}`,
    label: encodeURIComponent(pickupLocation.label),
  })}&dropoffLocations=${JSON.stringify(
    dropoffLocations.map((d) => ({
      id: `place_id:${d.id}`,
      label: encodeURIComponent(d.label),
    }))
  )}&parcelCode=${parcelCode}`;

  return callApi(`/public/estimate?${mapUrl}`);
};

export const httpGetPlaceDetailsByID = memoizeOne(
  async (placeId: string | undefined): Promise<GeoLocationData> => {
    const response = await axiosStatic.get<never, GeoDataResponse>(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${GOOGLE_API_KEY}`
    );
    const geolocation = response.data.results[0].geometry.location;
    const place = parseLocationData(
      response.data.results[0].address_components
    );
    return { geolocation, place };
  }
);
const parseLocationData = (
  /* eslint-disable camelcase */
  address_components: Array<{
    long_name: string;
    short_name: string;
    types: Array<string>;
  }>
) => {
  const extractLocality = (locality: string) =>
    address_components.filter((c) => c.types[0] === locality)[0];
  return {
    localGovt: extractLocality('administrative_area_level_2')
      ? extractLocality('administrative_area_level_2')?.long_name
      : '',
    city: extractLocality('locality')
      ? extractLocality('locality')?.long_name
      : '',
    state: extractLocality('administrative_area_level_1')
      ? extractLocality('administrative_area_level_1')?.long_name
      : '',
    country: extractLocality('country')
      ? extractLocality('country')?.long_name
      : '',
    postalCode: extractLocality('postal_code')
      ? extractLocality('postal_code')?.long_name
      : '',
  };
};

export const httpRequestPaymentLink = async (
  userId: string,
  payload: SendRequest,
  dispatcherId?: string
) => {
  try {
    const response = await axios.post(
      `/request/${userId}/shared-payment`,
      {
        ...payload,
        ...(dispatcherId && { dispatcherId }),
      },
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpShareLink = async (
  userId: string,
  link: string,
  phone: string
) => {
  try {
    const response = await axios.post<{
      userId: string;
      dispatcherId: string;
      payload: SendRequest;
    }>(`/request/${userId}/share-link`, { link, phone }, SupportHeader());
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpFetchSenders = async (userId: string) => {
  if (!userId) return [];
  try {
    const response = await axios.get(
      `/beneficiary/${userId}/sender`,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpAddSender = async (data: AddBeneficiary) => {
  const { userId, beneficiary } = data;
  try {
    const response = await axios.post(
      `/beneficiary/${userId}/sender`,
      beneficiary,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpFetchReceivers = async (userId: string) => {
  if (!userId) return [];
  try {
    const response = await axios.get(
      `/beneficiary/${userId}/receiver`,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpAddReceiver = async (data: AddBeneficiary) => {
  const { userId, beneficiary } = data;
  try {
    const response = await axios.post(
      `/beneficiary/${userId}/receiver`,
      beneficiary,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

interface InsuranceEstimatePayload {
  parcelValue: string;
  geoId: string;
  userId: string;
}

export const httpGetInsuranceEstimate = async (
  data: InsuranceEstimatePayload
) => {
  try {
    const response = await axios.get(
      `/insurance/${data.userId}/get-estimate/?goodsValue=${data.parcelValue}&geoId=${data.geoId}`,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpGetBatchEstimate = async ({
  pickupLocation,
  dropoffLocations,
}: {
  pickupLocation: string;
  dropoffLocations: string[];
}): Promise<{ estimate: number; geoId: string }> => {
  try {
    const response = await axios.post(
      `/public/batch-estimate`,
      {
        pickupLocation: {
          id: pickupLocation,
          label: pickupLocation,
        },
        dropoffLocations: [
          ...dropoffLocations.map((dropoffLocation) => ({
            id: dropoffLocation,
            label: dropoffLocation,
          })),
        ],
      },
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpProcessBulkEstimate = async ({
  payload,
  userId,
  role,
  roleId,
}: {
  payload: SendRequest & { batch: true };
  userId: string;
  roleId: string;
  role: string;
}): Promise<HttpDispatchResponse> => {
  try {
    const response = await axios.post(
      `/request/${userId}/request?roleId=${roleId}&role=${role}`,
      payload,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};
