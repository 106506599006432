import { getNationalNumber } from 'app/utilities/phoneUtils';
import { Field, FieldInputProps, FormikProps } from 'formik';
import phone from 'phone';
import React, { useState } from 'react';
import { ElrPhoneInputFlat } from 'ui-components';

export interface PhoneNumberInputProps {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  label: string;
  required?: boolean;
  hasIntegration: boolean;
  error: string;
  phoneValidityStatus: Function;
  optional?: boolean;
}

export const isPhoneValid = (value: string) => phone(value);

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  field,
  label,
  optional,
  form,
  phoneValidityStatus,
  ...other
}) => {
  const { errors } = form;
  const [phoneNumberValue, setInputNumber] = useState<string>(
    `${getNationalNumber(field.value)}`
  );
  const value = field.value || '';
  const error = errors[field.name];
  const numberValid = isPhoneValid(value).isValid;
  phoneValidityStatus(numberValid);

  const setNumberOnChange = (evt: {
    formattedNumber: string;
    mobile: string;
  }) => {
    setInputNumber(evt.mobile);
    field.onChange({
      target: { value: `${evt.formattedNumber}`, name: field.name },
    });
  };

  return (
    <>
      <div className="mb-2 text-xl text-elr-black">{label}</div>
      <div>
        <ElrPhoneInputFlat
          {...other}
          value={phoneNumberValue}
          onChange={setNumberOnChange}
        />
      </div>
      {error ||
        (!numberValid && (
          <div className="mt-2 text-sm text-elr-error">
            {error || 'Phone number is invalid'}
          </div>
        ))}
    </>
  );
};

type UserPhoneProp = {
  phoneValue?: string;
};

export const UserPhone: React.FC<UserPhoneProp> = ({ phoneValue = '' }) => {
  const phoneNumber = phoneValue?.startsWith('+234')
    ? phoneValue
    : `+234${phoneValue}`;

  return (
    <div className="mb-10 md:mx-7">
      <Field
        name="phone"
        label="Sender phone number"
        placeholder="Phone number"
        type="tel"
        value={phoneNumber}
        phoneValidityStatus={() => {}}
        component={PhoneNumberInput}
      />
    </div>
  );
};
