import Developer from 'app/commerce/modules/developer';
import CommerceSettings from 'app/commerce/modules/settings';
import OperatorIdentityVerification from 'app/operator/modules/identityVerification';
import OperatorRestaurant from 'app/operator/modules/restaurant';
import OperatorMarketRuns from 'app/operator/modules/market-runs';
import OperatorLifestyle from 'app/operator/modules/product';
import OperatorEssentials from 'app/operator/modules/essential';
import React from 'react';
import {
  Navigate,
  Routes as ReactRoutes,
  Route,
  BrowserRouter as Router,
  useLocation,
} from 'react-router-dom';
import Dashboard from './commerce/Dashboard';
import OperatorDashboard from './operator/Dashboard';
import CustomerEstimate from './commerce/customer-estimate';
import Logistics from './commerce/modules/logistics';
import OperatorLogistics from './operator/modules/logistics';
import PaymentGateWayResponse from './components/PaymentGateWayResponse';
import Ecommerce from './components/ecommerce';
import Events from './components/ecommerce/events/Event';
import EventDashboard from './components/ecommerce/events/EventDashboard';
import EventEdit from './components/ecommerce/events/EventEdit';
import EventList from './components/ecommerce/events/EventList';
import EventPreview from './components/ecommerce/events/EventPreview';
import EventQrScan from './components/ecommerce/events/EventQrScan';
import EcommerceInitial from './components/ecommerce/initial';
import { EventContactInformation } from './components/events/ContactInformation';
import { EventHome } from './components/events/Home';
import EventPurchaseSuccess from './components/events/PurchaseSuccess';
import BusinessInformation from './developer/modules/onboarding/BusinessInformation';
import { Roles } from './hooks/user';
import { Issues } from './modules/Issues';
import Requirements from './modules/Requirements';
import AboutUs from './modules/about';
import ApiPage from './modules/apiPage';
import Careers from './modules/careers';
import DeliveryPolicy from './modules/deliveryPolicy';
import { DispatchOrder } from './modules/dispatch/DispatchOrder';
import SuccessDispatchDetails from './modules/dispatch/SuccessDispatchDetails';
import FailedBilling from './modules/dispatch/billing/FailedBilling';
import VerifyBilling from './modules/dispatch/billing/VerifyBilling';
import ConfirmDetails from './modules/dispatch/components/estimate/ConfirmDetails';
import EventPage from './modules/eventPage';
import Landing from './modules/landing';
import Login from './modules/login/Login';
import LoginPortal from './modules/loginPortal';
import Onboarding from './modules/onboarding';
import TradingAddress from './modules/onboarding/TradingAddress';
import ForgotPassword from './modules/passwordSetting/ForgotPassword';
import ResetPassword from './modules/passwordSetting/ResetPassword';
import Policy from './modules/policies';
import CookiePolicy from './modules/policies/cookiePolicy';
import Faq from './modules/policies/faq';
import PrivacyPolicy from './modules/policies/privacyPolicy';
import RiderTermsAndCondtion from './modules/policies/riderTermsAndCondition';
import CustomerSla from './modules/policies/sla/index';
import TermsAndCondition from './modules/policies/termsAndCondition';
import Registration from './modules/registration';
import BillingRecord from './modules/settings/billing-record';
import BulkEstimate from './modules/settings/bulkEstimate';
import CompanyDetail from './modules/settings/company';
import CompanySetting from './modules/settings/company/CompanySetting';
import PaymentDetail from './modules/settings/payment';
import PersonalProfile from './modules/settings/profile';
import OperatorSettings from './operator/modules/settings';
import TrackParcel from './modules/trackParcel';
import Rate from './modules/trackParcel/Rate';
import TrackParcelUpdate from './modules/trackParcel/TrackParcelUpdate';
import Contact from './static/Contact';
import NoMatch from './static/NoMatch';
import { isSupport } from './utilities/helpers';
import { privateRoutes, publicRoutes } from './utilities/routes';
import OldDispatcherDashboard from './dispatcher/modules';
import MarketRuns from './components/ecommerce/marketRuns';
import MarketDashboard from './components/ecommerce/marketRuns/MarketDashboard';

interface AuthRouteProps {
  user: {
    id?: string;
    role: Roles;
  };
}

export const RequireAuth = ({
  children,
  auth,
}: {
  children: JSX.Element;
  auth: AuthRouteProps;
}) => {
  const location = useLocation();
  if (!auth?.user?.id) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    // @TODO clear storage/ with tokens
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export const RequireSupportAuth = ({
  children,
  auth,
}: {
  children: JSX.Element;
  auth: AuthRouteProps;
}) => {
  const location = useLocation();
  if (!auth?.user?.id || !isSupport(auth.user.role)) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    // @TODO clear storage/ with tokens
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export const TakeOverAuth = ({
  children,
  auth,
}: {
  children: JSX.Element;
  auth: AuthRouteProps;
}) => {
  const location = useLocation();
  if (auth?.user?.id || isSupport(auth.user.role)) {
    // this will check if the user is currently logged, then takes them to the dashboard

    return (
      <Navigate to={privateRoutes.selectPortal} state={{ from: location }} />
    );
  }
  return children;
};

export const Routes: React.FC<AuthRouteProps> = ({ user }) => (
  <Router>
    <ReactRoutes>
      <Route
        path={`${publicRoutes.dispatchDetails}/:requestId`}
        element={<SuccessDispatchDetails />}
      />
      <Route
        path={publicRoutes.paymentGatewayResponse}
        element={<PaymentGateWayResponse />}
      />
      <Route
        path={publicRoutes.registration}
        element={
          <TakeOverAuth auth={{ user }}>
            <Registration />
          </TakeOverAuth>
        }
      />
      <Route
        path={`${publicRoutes.rate}/:trackingId`}
        element={
          <RequireAuth auth={{ user }}>
            <Rate />
          </RequireAuth>
        }
      />
      <Route
        path={publicRoutes.login}
        element={
          <TakeOverAuth auth={{ user }}>
            <Login />
          </TakeOverAuth>
        }
      />
      <Route path={publicRoutes.estimate} element={<DispatchOrder />} />
      <Route path={publicRoutes.careers} element={<Careers />} />
      <Route path={publicRoutes.track} element={<TrackParcel />} />
      <Route path={publicRoutes.eventPage} element={<EventPage />} />
      <Route
        path={publicRoutes.trackParcelUpdate}
        element={<TrackParcelUpdate />}
      />
      <Route path={publicRoutes.issues} element={<Issues />} />
      <Route path={publicRoutes.tradeAddress} element={<TradingAddress />} />
      <Route path={publicRoutes.policy} element={<Policy />} />
      <Route path={publicRoutes.forgotPassword} element={<ForgotPassword />} />
      <Route path={publicRoutes.resetPassword} element={<ResetPassword />} />
      <Route
        path={`${publicRoutes.confirmDetails}/:trackingId`}
        element={<ConfirmDetails />}
      />
      <Route path={publicRoutes.privacyPolicy} element={<PrivacyPolicy />} />
      <Route
        path={publicRoutes.riderTermsAndCondition}
        element={<RiderTermsAndCondtion />}
      />
      <Route path={publicRoutes.faq} element={<Faq />} />
      <Route path={publicRoutes.contact} element={<Contact />} />
      <Route
        path={publicRoutes.termsAndCondition}
        element={<TermsAndCondition />}
      />

      <Route path={publicRoutes.cookiePolicy} element={<CookiePolicy />} />

      <Route path={publicRoutes.sla} element={<CustomerSla />} />
      <Route path={publicRoutes.eventQrScan} element={<EventQrScan />} />

      <Route
        path={privateRoutes.selectPortal}
        element={
          <RequireAuth auth={{ user }}>
            <LoginPortal />
          </RequireAuth>
        }
      />

      <Route path={publicRoutes.landing} element={<Landing />} />

      {/* BEGIN BILLING ROUTES */}
      <Route path={publicRoutes.billing}>
        <Route path={publicRoutes.verifyBilling} element={<VerifyBilling />} />
        <Route path={publicRoutes.failedBilling} element={<FailedBilling />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
      {/* END BILLING ROUTES */}

      {/* BEGIN DISPATCHER ROUTES */}
      <Route path={publicRoutes.dispatcher}>
        <Route
          path="dashboard"
          element={
            <RequireAuth auth={{ user }}>
              <OperatorDashboard />
            </RequireAuth>
          }
        />
        <Route
          path="old-dashboard"
          element={
            <RequireAuth auth={{ user }}>
              <OldDispatcherDashboard />
            </RequireAuth>
          }
        />
        <Route
          path="onboarding"
          element={
            <RequireAuth auth={{ user }}>
              <Onboarding />
            </RequireAuth>
          }
        />
        <Route
          path="logistics"
          element={
            <RequireAuth auth={{ user }}>
              <OperatorLogistics />
            </RequireAuth>
          }
        />
        <Route
          path="identity-verification"
          element={
            <RequireAuth auth={{ user }}>
              <OperatorIdentityVerification />
            </RequireAuth>
          }
        />
        <Route
          path="restaurant"
          element={
            <RequireAuth auth={{ user }}>
              <OperatorRestaurant />
            </RequireAuth>
          }
        />
        <Route
          path="product"
          element={
            <RequireAuth auth={{ user }}>
              <OperatorLifestyle />
            </RequireAuth>
          }
        />
        <Route
          path="essentials"
          element={
            <RequireAuth auth={{ user }}>
              <OperatorEssentials />
            </RequireAuth>
          }
        />
        <Route
          path="market"
          element={
            <RequireAuth auth={{ user }}>
              <OperatorMarketRuns />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.settings}
          element={
            <RequireAuth auth={{ user }}>
              <OperatorSettings />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.payment}
          element={
            <RequireAuth auth={{ user }}>
              <PaymentDetail />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.company}
          element={
            <RequireAuth auth={{ user }}>
              <CompanyDetail />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.profile}
          element={
            <RequireAuth auth={{ user }}>
              <PersonalProfile />
            </RequireAuth>
          }
        />

        <Route
          path={privateRoutes.requirements}
          element={
            <RequireAuth auth={{ user }}>
              <Requirements />
            </RequireAuth>
          }
        />

        <Route
          path={privateRoutes.bulkEstimate}
          element={
            <RequireAuth auth={{ user }}>
              <BulkEstimate />
            </RequireAuth>
          }
        />
      </Route>

      {/* END DISPATCHER ROUTES */}

      {/* ----- BEGING COMMERCE ROUTES ------ */}
      <Route path={publicRoutes.commerce}>
        <Route
          path="onboarding"
          element={
            <RequireAuth auth={{ user }}>
              <BusinessInformation />
            </RequireAuth>
          }
        />
        <Route
          path="dashboard"
          element={
            <RequireAuth auth={{ user }}>
              <Dashboard />
            </RequireAuth>
          }
        />

        <Route
          path="logistics"
          element={
            <RequireAuth auth={{ user }}>
              <Logistics />
            </RequireAuth>
          }
        />

        <Route
          path={privateRoutes.developer}
          element={
            <RequireAuth auth={{ user }}>
              <Developer />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.commerceSettings}
          element={
            <RequireAuth auth={{ user }}>
              <CommerceSettings />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.company}
          element={
            <RequireAuth auth={{ user }}>
              <CompanySetting />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.bulkEstimate}
          element={
            <RequireAuth auth={{ user }}>
              <BulkEstimate />
            </RequireAuth>
          }
        />
        <Route
          path={privateRoutes.billingRecord}
          element={
            <RequireAuth auth={{ user }}>
              <BillingRecord />
            </RequireAuth>
          }
        />

        <Route path={privateRoutes.ecommerce} element={<Ecommerce />}>
          <Route index element={<EcommerceInitial />} />
          <Route path={privateRoutes.events} element={<Events />} />
          <Route path={privateRoutes.eventsList} element={<EventList />} />
          <Route
            path={`:eventUUId/${privateRoutes.eventsEdit}`}
            element={<EventEdit />}
          />
          <Route
            path={privateRoutes.eventsPreview}
            element={<EventPreview />}
          />
          <Route
            path={`:eventUUId/${privateRoutes.eventsDashboard}`}
            element={<EventDashboard />}
          />
          <Route path={privateRoutes.marketRuns} element={<MarketRuns />} />
          <Route
            path={privateRoutes.marketDashboard}
            element={<MarketDashboard />}
          />
        </Route>
      </Route>

      {/* END COMMERCE ROUTES */}

      {/* BEGIN EVENT ROUTES */}
      <Route path={publicRoutes.event}>
        <Route path={':eventUUId'} element={<EventHome />} />
        <Route
          path={':eventUUId/contact-information'}
          element={<EventContactInformation />}
        />
        <Route
          path={':eventUUId/purchase-success'}
          element={<EventPurchaseSuccess />}
        />
      </Route>
      <Route path={publicRoutes.aboutUs} element={<AboutUs />} />
      <Route path={publicRoutes.deliveryPolicy} element={<DeliveryPolicy />} />
      <Route path={publicRoutes.apiPage} element={<ApiPage />} />
      <Route
        path={`${publicRoutes.customerEstimate}/:commerceSlug`}
        element={<CustomerEstimate />}
      />

      <Route path="*" element={<NoMatch />} />
    </ReactRoutes>
  </Router>
);
