import { useFormik } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import {
  ElrButton,
  ElrInput,
  ElrLocationSelect,
  ElrSwitch,
} from 'ui-components';
import { ElrUploadV2 } from 'ui-components/components/ElrUpload';
import TimePicker from 'ui-components/components/ErlTimePicker';
import * as Yup from 'yup';
import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import { BackIconWonWithBG } from '../SvgAssets';

const validationSchema = Yup.object({
  businessName: Yup.string().required('business name is required'),
  businessLocation: Yup.object({
    label: Yup.string().required('business location is required'),
  }).required('business location is required'),
  businessPhone: Yup.string().required('business Phone number is required'),
  businessEmail: Yup.string().required('business Email is required'),
  businessOpeningTime: Yup.string().required(
    'business opening time is required'
  ),
  businessClosingTime: Yup.string().required(
    'business closing time is required'
  ),
  bannerImage: Yup.mixed().optional(),
  notificationPreference: Yup.boolean(),
});

const BussinessInfoForm = () => {
  const navigate = useNavigate();

  const { handleSubmit, values, handleChange, setFieldValue, isValid } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        businessName: '',
        businessLocation: { label: '' },
        businessPhone: '',
        businessEmail: '',
        businessOpeningTime: '',
        businessClosingTime: '',
        bannerImage: null,
        notificationPreference: false,
      },
      validationSchema,
      onSubmit: async () => {
        navigate(
          `/${COMMERCE}/${privateRoutes.ecommerce}/${privateRoutes.marketDashboard}`
        );
      },
    });

  const isDisabled =
    !isValid || !values.businessOpeningTime || !values.businessClosingTime;

  return (
    <div className="flex justify-center">
      <div className="flex flex-col justify-center w-full">
        <section
          className="flex ml-6 cursor-pointer gap gap-x-2"
          onClick={() => navigate(-1)}
        >
          <BackIconWonWithBG />
          <p className="self-center">Back</p>
        </section>
        <div className="pt-12 mb-32">
          <header className="mb-6 text-center">
            <h1 className="mb-2 text-2xl font-normal leading-7 text-elr-black">
              Business Information
            </h1>
            <p className="text-lg leading-5 text-elr-black-400">
              Kindly fill in your business details
            </p>
          </header>
          <form
            className="w-full px-10 py-8 mx-auto mb-10 bg-elr-white-400"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col gap-3">
              <ElrInput
                onChange={handleChange}
                value={values.businessName}
                name="businessName"
                className="border-none rounded-none bg-elr-gray !h-12 outline-none"
                placeholder="Business Name"
              />
              <ElrInput
                onChange={handleChange}
                value={values.businessPhone}
                name="businessPhone"
                className="border-none rounded-none bg-elr-gray !h-12 outline-none"
                placeholder="Phone Number"
                type="number"
              />
              <div className="h-12">
                <ElrLocationSelect
                  className="pr-2 !mb-3"
                  controlHeight={44}
                  currentValue={values.businessLocation?.label}
                  placeholder="Address"
                  onChange={(value) => setFieldValue('businessLocation', value)}
                />
              </div>
              <ElrInput
                onChange={handleChange}
                value={values.businessEmail}
                name="businessEmail"
                className="border-none rounded-none bg-elr-gray !h-12 outline-none"
                placeholder="Email Address"
              />
              <div className="flex flex-row items-center justify-between gap-4 align-middle md:flex-row">
                <div className="flex items-center justify-between w-full md:w-1/2 h-12 bg-elr-gray">
                  <TimePicker
                    className="w-full bg-elr-gray"
                    placeholder="Opening Time"
                    clearIcon={null}
                    value={values.businessOpeningTime}
                    onChange={(time) =>
                      setFieldValue(
                        'businessOpeningTime',
                        time ? time.format('h:mm A') : ''
                      )
                    }
                  />
                </div>
                <div className="flex items-center justify-between w-full md:w-1/2 h-12 bg-elr-gray">
                  <TimePicker
                    className="w-full bg-elr-gray"
                    placeholder="Closing Time"
                    clearIcon={null}
                    value={values.businessClosingTime}
                    onChange={(time) =>
                      setFieldValue(
                        'businessClosingTime',
                        time ? time.format('h:mm A') : ''
                      )
                    }
                  />
                </div>
              </div>

              <div className="mb-5">
                <h3 className="mb-2">Business Image</h3>
                <ElrUploadV2
                  autoUpload={false}
                  accept=".jpeg,.jpg,.png"
                  tag={''}
                  onUploaded={() => {}}
                  onTempUpload={(file) => setFieldValue('bannerImage', file)}
                />
              </div>
              <div className="flex justify-between">
                <p className="w-5/12">
                  Notifications about new orders should be sent to the provided
                  phone number/email address
                </p>
                <ElrSwitch
                  isToggled={values.notificationPreference}
                  toggle={() =>
                    setFieldValue(
                      'notificationPreference',
                      !values.notificationPreference
                    )
                  }
                  parentClassName="bg-elr-gray-200 border-none cursor-pointer"
                  circleClassName="bg-elr-white "
                  toggledCircleClassName="!bg-elr-white "
                  toggledParentClassName="!bg-elr-black cursor-pointer"
                />
              </div>
            </div>
            <div className="mx-auto text-center mt-14">
              <ElrButton
                text="Continue"
                className="w-full rounded-sm bg-elr-black text-elr-white-400"
                onClick={() => handleSubmit()}
                disabled={isDisabled}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BussinessInfoForm;
