export const AGENTLIST = 'agentsList';
export const STATE_DISPATCH_PAYLOAD = 'dispatchPayload';
export const STATE_DISPATCH_DETAILS = 'dispatchDetails';
export const STATE_BUSINESS_INFORMATION = 'commerceBusinessinformation';
export const STATE_REQUEST_PAYLOAD = 'request_payload';
export const STATE_LOGISTICS_ANALYTICS_RECORDS = 'logisticsAnalyticsRecords';
export const UPCOMING_BATCH_REQUESTS = 'upcomingBatchRequests';
export const UPCOMING_SINGLE_REQUESTS = 'upcomingSingleRequests';
export const NEW_BATCH_REQUESTS = 'newBatchRequests';
export const NEW_SINGLE_REQUESTS = 'newSingleRequests';
export const NEW_COMMERCE_SINGLE_REQUESTS = 'newCommerceSingleRequests';
export const NEW_COMMERCE_BATCH_REQUESTS = 'newCommerceBatchRequests';
export const UPCOMING_COMMERCE_SINGLE_REQUESTS =
  'upcomingCommerceSingleRequests';
export const UPCOMING_COMMERCE_BATCH_REQUESTS = 'upcomingCommerceBatchRequests';
export const DELIVERIES_MADE_REQUESTS = 'deliveriesMadeRequests';
export const NEARBY_COMPANIES_REQUEST = 'nearbyCompaniesRequests';
export const DISPATCH_DELIVERIES_MADE = 'dispatchDeliveriesMade';
export const COMMERCE_DELIVERIES_MADE = 'commerceDeliveriesMade';
export const ESTIMATE_CHECKOUT_PAYLOAD = 'estimateCheckoutPayload';
export const COMPANY_AGENTS = 'companyAgents';
export const DISPATCHERS = 'dispatchers';
export const CURRENT_COUNTRY = 'current_country';
export const PARCEL_CODE = 'parcelCode';
export const BUSINESS_BRANCHES = 'businessBranches';

export const BATCH_LOCALITIES_REQUEST = 'batchLocalitiesRequest';
