import { axiosDefault as axios } from 'app/utilities/axios';
import { ProductsListData } from 'app/utilities/types/shared';
import { toast } from 'react-toastify';

interface productsParams {
  userId: string;
  nextPage: number;
}

export const httpGetDirectProductsList = async ({
  userId,
  nextPage,
}: productsParams): Promise<ProductsListData> => {
  const limit = 10;
  try {
    const response = await axios.get(`/product/public/${userId}`, {
      params: {
        page: nextPage,
        limit,
      },
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};
