import { httpProcessPendingPayment } from 'app/api/commerce';
import { httpWithdrawBalance } from 'app/api/payment';
import { httpTipFromWallet } from 'app/api/requests';
import { useCallback, useState } from 'react';
import useAsync from '../useAsync';
import { Roles, useGetProfile } from '../user';

export const useProcessPendingPayment = () => {
  const {
    id: userId,
    accountInformation: { id: commerceId },
  } = useGetProfile();

  const {
    execute: process,
    error,
    value: data,
    status,
  } = useAsync(() => httpProcessPendingPayment(userId, commerceId), false);

  return { process, error, data, status };
};

type Withdrawalpayload = {
  userId: string;
  roleId: string;
  role: Roles;
};

type WithrawBalanceHook = () => [
  (payload: Withdrawalpayload) => Promise<any>,
  boolean,
];

export const useWithrawBalanceHook: WithrawBalanceHook = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const withdrawBalance = useCallback(async (payload: Withdrawalpayload) => {
    setLoading(true);

    try {
      const result = await httpWithdrawBalance(payload);
      setLoading(false);
      return result;
    } catch (err: any) {
      setLoading(false);
      throw new Error(err);
    }
  }, []);
  return [withdrawBalance, loading];
};

type tipFromWalletParam = {
  trackingId: string;
  batch: boolean;
  tipAmount: number;
};

type TipFromWalletHook = () => [
  (params: tipFromWalletParam) => Promise<any>,
  boolean,
  string | null,
];

export const useTipFromWallet: TipFromWalletHook = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const tipFromWallet = useCallback(async (params: tipFromWalletParam) => {
    setLoading(true);
    setError(null);
    try {
      const result = await httpTipFromWallet(params);
      setLoading(false);
      return result;
    } catch (err: any) {
      setLoading(false);
      setError(err.message);
    }
    return null;
  }, []);
  return [tipFromWallet, loading, error];
};
