import {
  httpFetchInterestedRiders,
  httpFetchNearbyAgents,
  httpGetAgentsByDispatcherId,
  httpRateAgent,
} from 'app/api/agent';
import { httpFetchPartners } from 'app/api/batch';
import { IFetchNearbyAgentsPayload } from 'app/api/d';
import {
  useFetchInfiniteQuery,
  useFetchQueryOnce,
  useFetchRequestQueries,
} from 'app/components/dashboard/hooks';
import { COMPANY_AGENTS, NEARBY_COMPANIES_REQUEST } from 'app/state/constants';
import { AgentRatingInterface } from 'app/utilities/types/shared';
import { useCallback, useState } from 'react';
import { useGetProfile } from '../user';

export class useFetchError extends Error {}

type FetchNearByStationHook = () => [
  ({
    userId,
    state,
    country,
    page,
    location,
  }: {
    userId: string;
    state: string;
    country: string;
    page: number;
    location: { latitude: string; longitude: string };
  }) => Promise<void>,
  boolean,
  string | null,
  {},
];

export const useFetchNeabyStations: FetchNearByStationHook = () => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sendQuery] = useFetchRequestQueries();
  const [preresult, setResult] = useState<{}>({});

  const fetchNearbyStations = useCallback(
    async ({
      userId,
      state,
      country,
      page,
      location,
    }: {
      userId: string;
      state: string;
      country: string;
      page: number;
      location: { latitude: string; longitude: string };
    }) => {
      setError(null);
      setIsLoading(true);
      try {
        const result = await sendQuery(
          httpFetchPartners({
            userId,
            state,
            country,
            page,
            location,
          }),
          NEARBY_COMPANIES_REQUEST
        );
        setIsLoading(false);
        setResult(result);
        return result;
      } catch (err) {
        /** noop */
        setIsLoading(false);
      }
      return null;
    },
    [sendQuery]
  );
  return [fetchNearbyStations, isLoading, error, preresult];
};

export const useFetchAgentsData = () => {
  const {
    id: userId,
    accountInformation: { id: dispatcherId },
  } = useGetProfile();

  return useFetchInfiniteQuery({
    key: COMPANY_AGENTS,
    fn: httpGetAgentsByDispatcherId,
    payload: { userId, dispatcherId },
  });
};

type SubmitAgentRatingType = () => [
  (
    userId: string,
    ratingDetails: AgentRatingInterface
  ) => Promise<AgentRatingInterface | null>,
  boolean,
];

export const useSubmitAgentRating: SubmitAgentRatingType = () => {
  const [isLoading, setIsLoading] = useState(false);

  const submitAgentRating = async (
    userId: string,
    ratingDetails: AgentRatingInterface
  ) => {
    setIsLoading(true);
    try {
      const result = await httpRateAgent(userId, ratingDetails);
      setIsLoading(false);
      return result;
    } catch (err) {
      // no op
      setIsLoading(false);
    }
    return null;
  };

  return [submitAgentRating, isLoading];
};

export const useFetchInterestedRiders = (
  userId: string,
  requestId: string,
  enabled: boolean = true
) => {
  const response = useFetchQueryOnce({
    key: 'interestedRiders',
    fn: httpFetchInterestedRiders,
    payload: { userId, requestId },
    querySettings: { enabled },
  });

  return { ...response, data: response.data ?? [] };
};

export const useFetchNearbyAgents = (
  userId: string,
  agentPayload: IFetchNearbyAgentsPayload,
  enabled: boolean = true
) => {
  const response = useFetchQueryOnce({
    key: 'nearbyAgents',
    fn: httpFetchNearbyAgents,
    payload: { userId, payload: agentPayload },
    querySettings: { enabled },
  });

  return { ...response, data: response.data ?? [] };
};
