import { useFormik } from 'formik';
import { ElrButton, ElrInput } from 'ui-components';
import { useGetProfile } from 'app/hooks/user';
import SlideIn from 'app/components/SlideIn/SlideIn';
import { useState } from 'react';
import { PencilIcon } from 'app/components/SvgAssets';
import validationSchema from './validationSchema';

interface AccountDetailsProps {}

const AccountDetails: React.FC<AccountDetailsProps> = () => {
  const {
    id: userId,
    accountInformation,
    currentAccessRole,
    ...profile
  } = useGetProfile();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { values, setFieldValue, handleSubmit, isValid } = useFormik({
    initialValues: {
      lastName: profile?.lastname || '',
      firstName: profile?.firstname || '',
      email: profile?.email || '',
      phone: profile?.phone || '',
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: async () => {
      setIsModalOpen(false);
    },
    enableReinitialize: true,
  });

  const handleFieldChange = (field: string, value: string) => {
    const numericValue = Math.max(0, Number(value));
    setFieldValue(field, numericValue);
  };

  const isFormDisabled =
    !isValid || values.firstName.trim() === '' || values.lastName.trim() === '';

  const renderEditInfo = (
    <SlideIn
      close={() => setIsModalOpen(false)}
      shown={isModalOpen}
      leftMarginOffset="md:ml-[60%]"
    >
      <p className="text-center pb-6 text-xl">Personal Details</p>
      <form onSubmit={handleSubmit}>
        <div className="w-full flex flex-col gap-7 py-8 mx-auto mb-10 bg-elr-white-400">
          <div className="w-full">
            <p className="text-base pb-1.5 text-elr-black-400">First Name</p>
            <div className="text-elr-black text-base flex justify-center align-middle items-center h-12">
              <ElrInput
                placeholder="First Name"
                value={String(values.firstName)}
                onChange={(e) => handleFieldChange('firstName', e.target.value)}
                className="border-none bg-elr-gray !h-12 outline-none"
                type="text"
              />
            </div>
          </div>
          <div className="w-full">
            <p className="text-base pb-1.5 text-elr-black-400">Last Name</p>
            <div className="text-elr-black text-base flex justify-center align-middle items-center h-12">
              <ElrInput
                placeholder="Last Name"
                value={String(values.lastName)}
                onChange={(e) => handleFieldChange('lastName', e.target.value)}
                className="border-none bg-elr-gray !h-12 outline-none"
                type="text"
              />
            </div>
          </div>
          <div className="w-full">
            <p className="text-base pb-1.5 text-elr-black-400">Email</p>
            <div className="text-elr-black text-base flex justify-center align-middle items-center h-12">
              <ElrInput
                placeholder="Email"
                value={String(values.email)}
                onChange={(e) => handleFieldChange('email', e.target.value)}
                className="border-none bg-elr-gray !h-12 outline-none"
                type="text"
              />
            </div>
          </div>
          <div className="w-full">
            <p className="text-base pb-1.5 text-elr-black-400">Phone Number</p>
            <div className="text-elr-black text-base flex justify-center align-middle items-center h-12">
              <ElrInput
                placeholder="Phone Number"
                value={String(values.phone)}
                onChange={(e) => handleFieldChange('phone', e.target.value)}
                className="border-none bg-elr-gray !h-12 outline-none"
                type="number"
              />
            </div>
          </div>
        </div>
        <ElrButton
          text="Save Changes"
          className="w-full bg-elr-black text-white rounded-md text-base mt-24"
          submit
          disabled={isFormDisabled}
        />
      </form>
    </SlideIn>
  );

  return (
    <div className="weight-normal text-sm relative border-elr-gray bg-white border-opacity-30 px-8 py-6">
      <div
        className="absolute right-5 top-6 text-base px-8 py-3 text-elr-black flex gap-3 rounded-md items-center justify-center cursor-pointer bg-elr-gray text-center"
        onClick={() => setIsModalOpen(true)}
      >
        <p>Edit</p>
        <PencilIcon />
      </div>

      {isModalOpen && renderEditInfo}

      <p className="text-xl pb-7">Personal Details</p>
      <div className="grid grid-cols-1 md:grid-rows-2 md:grid-flow-col gap-7 md:gap-8">
        <div className="flex flex-col">
          <p className="text-base pb-2 text-elr-black-400">Full Name</p>
          <p className="text-elr-black text-base">
            {profile.firstname} {profile.lastname}
          </p>
        </div>

        <div className="flex flex-col">
          <p className="text-base pb-2 text-elr-black-400">Phone Numbers</p>
          <p className="text-elr-black text-base">{profile.phone}</p>
        </div>

        <div className="flex flex-col">
          <p className="text-base pb-2 text-elr-black-400">Email Address</p>
          <p className="text-elr-black text-base">{profile.email}</p>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
