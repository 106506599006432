import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FinanceAggregate, RequestPayoutResponse } from 'app/api/d';
import {
  httpUpdateDispatcherBankInformation,
  httpUpdateSenderBankInformation,
} from 'app/api/dispatcher';
import { httpFinanceAggregate, httpRequestPayout } from 'app/api/financial';
import { VerifiedInformation, verifyBankInformation } from 'app/api/payment';
import { BankDetails } from 'app/utilities/types/dispatcherTypes';
import { useCallback, useState } from 'react';
import { Roles } from '../user';

type RequestPayoutHook = () => [
  (userId: string, role: Roles) => Promise<RequestPayoutResponse>,
  string | null,
];
export const useRequestPayout: RequestPayoutHook = () => {
  const [error, setError] = useState<string | null>(null);

  const initiateRequestPayout = useCallback(
    async (userId: string, role: Roles) => {
      setError(null);

      try {
        const [result, e] = await httpRequestPayout(userId, role);
        setError(e);
        return result;
      } catch (err: any) {
        setError(err);
        return err;
      }
    },
    []
  );

  return [initiateRequestPayout, error];
};

interface AggregateResponse {
  isLoading: boolean;
  isError: boolean;
  payload: FinanceAggregate | undefined;
}
type FetchFinanceAggregateHook = (
  userId: string,
  role: Roles
) => [() => AggregateResponse, boolean, boolean];

export const useFetchAggregate: FetchFinanceAggregateHook = (
  userId: string,
  role: Roles
) => {
  const {
    isLoading,
    isError,
    data: payload,
  } = useQuery({
    queryKey: ['financeAggregate'],
    queryFn: () => httpFinanceAggregate(userId, role),
  });
  const fetchAggregate = useCallback(
    () => ({
      isLoading,
      isError,
      payload,
    }),
    [isError, isLoading, payload]
  );
  return [fetchAggregate, isLoading, isError];
};

type UpdateBankInformationHook = () => [
  (payload: BankDetails, userId: string, role: Roles) => Promise<void>,
  boolean,
];

export const useUpdateBankInformation: UpdateBankInformationHook = () => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateBankInformation = useCallback(
    // eslint-disable-next-line consistent-return
    async (payload: BankDetails, userId: string, role: Roles) => {
      const updateBankInfoByRole: { [key: string]: () => void } = {
        sender: () => httpUpdateSenderBankInformation(payload, userId),
        dispatcher: () => httpUpdateDispatcherBankInformation(payload, userId),
      };

      setIsLoading(true);
      try {
        const data = await queryClient.fetchQuery({
          queryKey: ['updateBankInfo'],
          queryFn: () => updateBankInfoByRole[role](),
        });
        setIsLoading(false);
        return data;
      } catch (err: any) {
        setIsLoading(false);
        throw new Error(err);
      }
    },

    []
  );

  return [updateBankInformation, isLoading];
};

interface VerifyBankPayload {
  accountNumber: string;
  bankCode: string;
}

type VerifyBankHook = [
  (payload: VerifyBankPayload) => Promise<VerifiedInformation>,
  boolean,
];
export const useVerifyBank = (): VerifyBankHook => {
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const fetchBankName = async (payload: VerifyBankPayload) => {
    setIsFetching(true);
    try {
      const response = await verifyBankInformation(payload);
      setIsFetching(false);
      return response;
    } catch (error: any) {
      setIsFetching(false);
      return error.message;
    }
  };
  return [fetchBankName, isFetching];
};
