import * as Yup from 'yup';

const validationSchema = Yup.object({
  lastName: Yup.string().required('Last name is required'),
  firstName: Yup.string().required('First name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
});

export default validationSchema;
