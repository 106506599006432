import { useQueryClient } from '@tanstack/react-query';
import { httpUpdateBankDetails } from 'app/api/commerce';
import { httpUserProfile } from 'app/api/user';
import SlideIn from 'app/components/SlideIn/SlideIn';
import { useVerifyBank } from 'app/hooks/requests/finance';
import { useGetProfile } from 'app/hooks/user';
import { queryKeys } from 'app/utilities/query';
import CheckCircle from 'assets/images/CheckCircle.svg';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ElrBankSelect, ElrButton, ElrInput } from 'ui-components';
import * as Yup from 'yup';

const NUBAN_DIGIT_LENGTH = 10;

const validationSchema = Yup.object({
  bank: Yup.object().shape({
    value: Yup.string().required('Bank name is required'),
    code: Yup.string().required('Bank code is required'),
  }),
  accountNumber: Yup.string()
    .required('Account number is required')
    .matches(/^[0-9]{10}$/, 'Account number must be 10 digits'),
});

const BankDetails = () => {
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    id: userId,
    accountInformation: { id: roleId, bankDetails },
    currentAccessRole,
  } = useGetProfile();
  const [accountName, setAccountName] = useState<string>('');

  const { handleSubmit, values, setFieldValue, isValid } = useFormik({
    initialValues: {
      accountNumber: '',
      bank: {
        value: '',
        code: '',
      },
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: async () => {
      try {
        await httpUpdateBankDetails(
          {
            bankDetails: {
              bankCode: values.bank.code,
              bankName: values.bank.value,
              accountName,
              accountNumber: values.accountNumber,
            },
            commerceId: roleId,
          },
          userId
        );
        queryClient.fetchQuery({
          queryKey: queryKeys.user,
          queryFn: () => httpUserProfile(currentAccessRole),
        });

        setIsModalOpen(false);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const [fetchBankName, isFetching] = useVerifyBank();

  const handleVerifyBank = async () => {
    if (!values.bank.code) {
      toast.error('Please select bank', {
        closeOnClick: true,
        autoClose: 3000,
      });
      return;
    }
    if (values.accountNumber.length === NUBAN_DIGIT_LENGTH) {
      const response = await fetchBankName({
        accountNumber: values.accountNumber,
        bankCode: values.bank.code,
      });

      if (response.status) {
        setFieldValue('bank', {
          ...values.bank,
          accountName: response.account_name,
        });
        setAccountName(response.account_name);
      }
    }
  };

  useEffect(() => {
    if (!isValid) {
      setAccountName('');
    }
    if (
      isValid &&
      values.accountNumber &&
      values.accountNumber.length === NUBAN_DIGIT_LENGTH &&
      !isFetching
    ) {
      handleVerifyBank();
    }
  }, [isValid]);

  const renderEditInfo = (
    <SlideIn
      close={() => setIsModalOpen(false)}
      shown={isModalOpen}
      leftMarginOffset="md:ml-[60%]"
    >
      <p className="pb-6 text-xl text-center">Bank Information</p>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col w-full gap-5 py-8 mx-auto mb-10 bg-elr-white-400">
          <div className="w-full">
            <p className="pb-2 text-base text-elr-black-400">Bank Name</p>
            <ElrBankSelect
              placeholder="Bank name"
              onChange={(bank) => setFieldValue('bank', bank)}
              currentValue={values.bank.value}
            />
          </div>

          <div className="w-full">
            <p className="pb-2 text-base text-elr-black-400">Account Number</p>
            <ElrInput
              className="h-12 border-none outline-none bg-elr-gray"
              value={values.accountNumber}
              onChange={(e) =>
                setFieldValue(
                  'accountNumber',
                  e.target.value.replace(/\D/g, '')
                )
              }
              placeholder="Account Number"
            />
          </div>
          {accountName && (
            <div className="flex flex-row items-center gap-x-1">
              <img src={CheckCircle} alt="check-circle" />
              <p className="pt-0.5 text-base text-elr-black">{accountName}</p>
            </div>
          )}
        </div>
        <ElrButton
          text="Save Changes"
          className="w-full mt-24 text-base text-white rounded-md bg-elr-black"
          submit
          disabled={!isValid || isFetching}
        />
      </form>
    </SlideIn>
  );

  return (
    <div className="relative px-8 py-6 text-sm bg-white weight-normal border-elr-gray border-opacity-30">
      <div
        className="absolute flex items-center justify-center gap-3 px-8 py-3 text-base text-center rounded-md cursor-pointer right-5 top-6 text-elr-black bg-elr-gray"
        onClick={() => setIsModalOpen(true)}
      >
        <p>Change Bank</p>
      </div>
      {isModalOpen && renderEditInfo}
      <p className="text-xl pb-7">Bank Information</p>
      <div className="grid grid-cols-1 md:grid-rows-1 md:grid-flow-col gap-7 md:gap-0">
        <div className="flex flex-col">
          <p className="pb-2 text-base text-elr-black-400">Bank Name</p>
          <p className="text-base text-elr-black">
            {bankDetails?.bankName || 'N/A'}
          </p>
        </div>
        <div className="flex flex-col">
          <p className="text-base text-elr-black-400">Account Number</p>
          <div className="flex pt-2 pb-2 text-base">
            <p className="pt-1 pr-4 text-base text-elr-black">
              {bankDetails?.accountNumber || 'N/A'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
