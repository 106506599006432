export const loginContainerClass = () =>
  'w-11/12 md:w-96 rounded-2xl bg-elr-white-400  flex flex-col items-center';

export const loginTextClass = () => 'mt-14 text-center mb-7 text-xl ';

export const loginFormClass = () => 'w-5/6 md:w-72';

export const loginButtonContainer = () => '';

export const loginButtonClass = () =>
  'bg-elr-black text-white text-lg mb-4 md:mb-4 w-full';

export const loginDivClass = () =>
  'flex flex-col h-screen items-center bg-elr-gray-500';
