import SupportHeader from 'app/utilities/HttpSupportHeader';
import { Session } from 'app/utilities/Session';
import { axiosDefault as axios } from 'app/utilities/axios';
import {
  AgentRatingInterface,
  IPaginateOptions,
  NearbyAgentsInterface,
  PaginateResponseInterface,
} from 'app/utilities/types/shared';
import { toast } from 'react-toastify';
import { GetAgentsInterface, IFetchNearbyAgentsPayload } from './d';
import { GetAgentsResponse } from './types';

const tenantData = Session.tenantData();

export const httpGetAgents = async (
  userId: string,
  dispatcherId: string,
  options: IPaginateOptions = { limit: 50, offset: 0, sort: 'desc' }
): Promise<GetAgentsInterface & PaginateResponseInterface> => {
  try {
    const response = await axios.get(
      `/agent/${userId}/agents?dispatcherId=${dispatcherId}`,
      {
        params: options,
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

/**
 *@description Get available agents for the logged company
 */
interface IGetAvailableAgentsOptions {
  country: keyof typeof tenantData;
  state: string;
  dispatcherId?: string;
}
export const httpGetAvailableAgents = async (
  userId: string,
  options: IGetAvailableAgentsOptions & IPaginateOptions
): Promise<GetAgentsInterface> => {
  try {
    const response = await axios.get(`/agent/${userId}/available-agents`, {
      params: options,
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

/**
 *@description Get available agents for the logged company
 */
interface GetAgentsByDispatcherIdParams {
  userId: string;
  dispatcherId: string;
  nextPage: number;
}
export const httpGetAgentsByDispatcherId = async ({
  userId,
  dispatcherId,
  nextPage,
}: GetAgentsByDispatcherIdParams) => {
  try {
    const response = await axios.get<never, GetAgentsResponse>(
      `/agent/${userId}/agents`,
      {
        params: { dispatcherId, page: nextPage },
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpFetchNearbyAgents = async ({
  userId,
  payload,
}: {
  userId: string;
  payload: IFetchNearbyAgentsPayload;
}): Promise<NearbyAgentsInterface[]> => {
  try {
    const response = await axios.post(
      `/tracking/${userId}/agents-distance`,
      { ...payload },
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpFetchInterestedRiders = async ({
  userId,
  requestId,
}: {
  userId: string;
  requestId: string;
}): Promise<NearbyAgentsInterface[]> => {
  try {
    const response = await axios.get(
      `/agent/${userId}/interested-riders/${requestId}`,
      { ...SupportHeader() }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

interface ReAssignAgentPayload {
  requestId: string;
  dispatcherId: string;
  agentId: string;
}

export const httpReAssignAgents = async (
  userId: string,
  payload: ReAssignAgentPayload
): Promise<ReAssignAgentPayload> => {
  try {
    const response = await axios.post(
      `/request/${userId}/reassign-request`,
      { ...payload },
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpFetchUnmanagedAgents = async (pageParam: {
  userId: string;
  options: IPaginateOptions;
  pageParam?: any;
}): Promise<GetAgentsInterface> => {
  const { userId, options } = pageParam;
  try {
    const response = await axios.get(
      `/dispatcher/${userId}/get-agents-unmanaged`,
      {
        params: options,
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpRateAgent = async (
  userId: string,
  payload: AgentRatingInterface
): Promise<AgentRatingInterface> => {
  try {
    const response = await axios.post(
      `/agent/${userId}/review`,
      { ...payload },
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};
