/* eslint-disable default-param-last */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { BatchedInterface } from 'app/api/d';
import { InitialInterfaceData } from 'app/api/types';
import DashboardContainer from 'app/components/DashboardContainer';
import BatchContainer from 'app/components/dashboard/BatchContainer';
import BatchUpcomingContainer from 'app/components/dashboard/BatchUpcomingContainer';
import {
  useFetchDeliveriesMade,
  useFetchNewBatchRequests,
  useFetchNewRequests,
  useFetchUpcomingRequests,
} from 'app/hooks/requests/dashboard';
import DeliveriesMade from 'app/platform/modules/DeliveriesMade';
import {
  batchRequestTabs,
  checkCompanyProfile,
  dispatchTabs,
} from 'app/utilities/helpers';
import { privateRoutes } from 'app/utilities/routes';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetProfile } from '../../hooks/user';
import DashboardPanel from '../../platform/modules/DashboardPanel';

const OldDispatcherDashboard: React.FC = () => {
  const {
    accountInformation: { areasOfOperations, companyName },
    currentAccessRole,
    isAnOperator,
  } = useGetProfile();
  const history = useNavigate();
  const [tabs] = useState(dispatchTabs);

  // hooks for fetching requests (single)
  const { latestData: newSingleRequestData } =
    useFetchNewRequests('marketplace');
  const { latestData: singleUpcomingRequestData } =
    useFetchUpcomingRequests('');
  const { latestData: deliveriesMadeRequestData } = useFetchDeliveriesMade();

  // hooks for fetching requests (batches)
  const { latestData: newBatchRequestData } = useFetchNewBatchRequests(
    batchRequestTabs[0].id
  );

  const [batchDeliveriesMade] = useState<BatchedInterface>({
    batches: InitialInterfaceData,
  });

  const searchFields = useMemo(
    () => ({
      areasOfOperations,
      companyName,
    }),
    [areasOfOperations, companyName]
  );

  const [stepState, setStateState] = useState<number>(0);

  useEffect(() => {
    const { isNotEmpty } = checkCompanyProfile(searchFields);

    if (isNotEmpty) {
      return history(
        `${privateRoutes.onboarding(currentAccessRole)}?incomplete=true`
      );
    }
  }, []);

  const panels = [
    null,
    <BatchContainer tabs={tabs} />,
    <BatchUpcomingContainer />,
    <DeliveriesMade displayOriginalFees />,
  ];

  const onChangePanel = (index: number) => {
    setStateState(index);
  };

  const showUnassignedAgents = () => {
    onChangePanel(5);
  };

  const newRequestsTotal = isAnOperator
    ? newSingleRequestData.totalDocs + newBatchRequestData.totalDocs
    : newBatchRequestData.totalDocs;

  const upcomingRequestsTotal = singleUpcomingRequestData.totalDocs;

  const deliveriesMadeTotal =
    deliveriesMadeRequestData.totalDocs + batchDeliveriesMade.batches.totalDocs;

  return (
    <DashboardContainer
      titleTag="Errandlr - Dispatcher Dashboard"
      panelComponent={panels[stepState] ? panels[stepState] : null}
      dashboardPanel={
        <DashboardPanel
          totalDocs={{
            newRequestsTotal,
            upcomingRequestsTotal,
            deliveriesMadeTotal,
          }}
          onClick={onChangePanel}
          panelActive={stepState}
        />
      }
      showUnassignedAgents={showUnassignedAgents}
    />
  );
};

export default OldDispatcherDashboard;
