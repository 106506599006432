import { MobileHeader } from 'app/operator/components/Header';
import TopNavigationMenu from 'app/operator/components/menu/TopNavigationMenu';
import {
  BasketIconWonWithBG,
  CutleryIconWonWithBG,
  LeafIconWonWithBG,
  LogisticsBoxWithBG,
  ProfileIconWithBG,
  WearIconWonWithBG,
} from 'app/operator/components/SvgAssets';
import { privateRoutes, publicRoutes } from 'app/utilities/routes';
import { useNavigate } from 'react-router-dom';
import { ElrPageTitle } from 'ui-components';

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <div>
      <ElrPageTitle title="Commerce Dashboard" />
      <div className="bg-white">
        <div className="flex justify-between align-middle w-full md:mx-auto items-center">
          <div className="w-full md:w-11/12 md:mx-auto">
            <MobileHeader mdHide />
          </div>
          <TopNavigationMenu containerClass="md:mr-0" />
        </div>
      </div>
      <div className="flex flex-col justify-center align-middle items-center h-full max-w-4xl md:mx-auto mx-4 my-20">
        <div className="flex flex-col justify-center align-middle items-center gap-2 pb-8">
          <h2 className="text-2xl">What would you like to do today?</h2>
          <p className="text-lg text-elr-black-400">Select an option</p>
        </div>
        <div className="grid md:grid-cols-column-three grid-cols-column-two gap-6 my-4 w-full">
          <OutletCard
            icon={<LogisticsBoxWithBG />}
            label="Logistics"
            onClick={() =>
              navigate(`${publicRoutes.dispatcher}/${privateRoutes.logistics}`)
            }
          />
          <OutletCard
            icon={<ProfileIconWithBG />}
            label="Identity Verification"
            disabled
          />
          <OutletCard
            icon={<LeafIconWonWithBG />}
            label="Market Runs"
            disabled
          />
          <OutletCard
            icon={<CutleryIconWonWithBG />}
            label="Restaurant"
            disabled
          />
          <OutletCard icon={<WearIconWonWithBG />} label="Lifestyle" disabled />
          <OutletCard
            icon={<BasketIconWonWithBG />}
            label="Essentials"
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

interface OutletCardProps {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

export const OutletCard: React.FC<OutletCardProps> = ({
  icon,
  label,
  onClick,
  className,
  disabled = false,
}) => (
  <div
    className={`bg-elr-white-400 md:py-12 py-10 px-8 w-full md:h-52 h-fit rounded-lg cursor-pointer ${
      disabled ? 'opacity-50' : ''
    } ${className}`}
    onClick={disabled ? undefined : onClick}
  >
    <div className="flex flex-col align-middle justify-center items-center">
      {icon}
      <p className="text-lg mt-6 text-elr-black leading-5">{label}</p>
    </div>
  </div>
);
