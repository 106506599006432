/* eslint-disable no-await-in-loop */
import { useQueryClient } from '@tanstack/react-query';
import { SendRequest } from 'app/api/d';
import SlideIn from 'app/components/SlideIn/SlideIn';
import { PAYSTACK_PUBLIC_KEY } from 'app/constants';
import {
  useGetTemporaryTrackingId,
  useGetWalletBalance,
} from 'app/hooks/requests/platform';
import { useGetProfile } from 'app/hooks/user';
import {
  STATE_DISPATCH_PAYLOAD,
  STATE_REQUEST_PAYLOAD,
} from 'app/state/constants';
import {
  calculatePaymentFee,
  currencyFormatter,
  requestEstimation,
} from 'app/utilities/helpers';
import { HttpDispatchResponse } from 'app/utilities/types/shared';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePaystackPayment } from 'react-paystack';
import { ElrButton, ElrDropdownButton } from 'ui-components';
import { ElrCopy } from 'ui-components/components/ElrCopy/ElrCopy';
import FundWallet from './FundWallet';

export interface AuthModalInterface {
  showSlideIn: boolean;
  setShowSlideIn: (state: boolean) => void;
  onUserSelectWalletOption?: () => void;
}

const WalletSlideIn: React.FC<AuthModalInterface> = ({
  showSlideIn: showModal,
  setShowSlideIn: setShowModal,
  onUserSelectWalletOption,
}) => {
  const {
    id,
    accountInformation: { id: roleId },
    email,
  } = useGetProfile();
  const queryClient = useQueryClient();
  const getRequestPayload = queryClient.getQueryData<SendRequest>([
    STATE_REQUEST_PAYLOAD,
  ]);
  const [getWalletBalance, wallet] = useGetWalletBalance(id, roleId);
  const [walletBalance, setWalletBalance] = useState(0);
  const [walletID, setWalletID] = useState('');
  const [transferDetails, setTransferDetails] = useState({
    paystackAccountName: '',
    paystackAccountNumber: '',
  });
  const [paymentInitiated, onPaymentInitiated] = useState(false);
  const dispatchRequestPayload = queryClient.getQueryData([
    STATE_DISPATCH_PAYLOAD,
  ]) as HttpDispatchResponse;
  const [generateTracking, generatedTrackingID] = useGetTemporaryTrackingId(
    id,
    dispatchRequestPayload.batch
  );
  const [showFundModal, setShowFundModal] = useState(false);
  const [showTransferDetails, setShowTransferDetails] = useState(false);
  const getBalance = useCallback(async () => {
    const account: any = await getWalletBalance();
    if (account.data) {
      const { walletId, balance, paystackAccountName, paystackAccountNumber } =
        account.data;
      setWalletBalance(balance);
      walletId && setWalletID(walletId);
      paystackAccountName &&
        setTransferDetails({ paystackAccountName, paystackAccountNumber });
    }
  }, [wallet?.balance]);
  useEffect(() => {
    getBalance();
  }, [getBalance]);
  useMemo(() => generateTracking(), [generateTracking]);
  const estimatedAmountForRequest =
    requestEstimation(dispatchRequestPayload!) || 0;
  const balanceIsLow = walletBalance < estimatedAmountForRequest;
  const displayModal = () => {
    const showModalByDefault = !showFundModal;
    return showModalByDefault;
  };
  const setDisplayFundModal = () => setShowFundModal(false);
  const amountToTopUpWithToBalance = estimatedAmountForRequest - walletBalance;
  const [price, setPrice] = useState(amountToTopUpWithToBalance);
  useEffect(() => {
    setPrice(amountToTopUpWithToBalance);
  }, [amountToTopUpWithToBalance]);
  const disableFundButton = () =>
    // disable if price in input is less than amount to be paid for.
    price < amountToTopUpWithToBalance;
  const paymentTotal = calculatePaymentFee(price);
  const config = {
    email: email || `${getRequestPayload?.phone}@errandlr.com`,
    amount: paymentTotal * 100,
    publicKey: PAYSTACK_PUBLIC_KEY,
    channels: ['card', 'ussd', 'bank_transfer'],
    metadata: {
      senderId: roleId,
      accountId: id,
      requestWithGeoHash: getRequestPayload,
      isPlatformRequest: true,
      request: generatedTrackingID,
    },
  };
  const initializePayment = usePaystackPayment(config as any);
  async function callGetBalanceExponentially(
    condition: (balance: number) => boolean,
    maxTries: number
  ): Promise<number> {
    let i = 0;
    let {
      data: { balance },
    } = await getWalletBalance(); // assuming getBalance is an async function that returns a promise
    while (!condition(balance) && i < maxTries) {
      const delay = 2 ** i * 1000; // exponential delay
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, delay)); // wait for the delay
      const acct = await getWalletBalance(); // get the new balance
      balance = acct.data.balance;
      // eslint-disable-next-line no-plusplus
      i++; // increment the counter
    }
    return balance;
  }
  const condition = (balance: number) => balance >= estimatedAmountForRequest;
  const maxTries = 10;
  const onSuccess = () => {
    // wallet funded, then head back to where they click.
    callGetBalanceExponentially(condition, maxTries)
      .then(() => {
        onPaymentInitiated(false);
      })
      .catch((_) => {
        onPaymentInitiated(false);
      });
    onPaymentInitiated(true);
  };
  const onClose = () => {
    callGetBalanceExponentially(condition, maxTries)
      .then(() => {
        onPaymentInitiated(false);
      })
      .catch((_) => onPaymentInitiated(false));
    onPaymentInitiated(true);
  };
  const goToPaystack = () => {
    const MINIMUM_PAYSTACK = 50;
    price >= MINIMUM_PAYSTACK && initializePayment({ onSuccess, onClose });
  };
  return displayModal() ? (
    <SlideIn shown={showModal} close={() => setShowModal(!showModal)}>
      <div className="md:mx-3">
        <h1 className="font-normal leading-5 text-center text-18 md:text-left">
          Checkout
        </h1>
        <div className="flex justify-between pt-8 md:pt-5">
          <p className="leading-5 opacity-60 text-12 md:text-14">
            Order Amount:
          </p>
          <p className="leading-5 text-18">
            {currencyFormatter(estimatedAmountForRequest || 0, 'en-NG')}
          </p>
        </div>
        <div className="pt-14">
          <div className="flex justify-between">
            <h2 className="pb-2 leading-5 text-16">Pay via wallet</h2>
            {balanceIsLow && (
              <p className="text-sm text-center text-elr-error text-opacity-60">
                Insufficient Funds.
              </p>
            )}
          </div>
          <div className="p-4 mb-5 border rounded-md  border-elr-green border-opacity-30">
            <div className="flex justify-between">
              <h3 className="leading-5 text-14 md:text-18">Your Wallet</h3>
              <p className="leading-5 text-10 opacity-40">
                Wallet ID: {walletID.toUpperCase()}
              </p>
            </div>
            {!paymentInitiated ? (
              <>
                <div className="flex flex-col items-center justify-center gap-2 pt-6">
                  <p className="leading-5 text-12">Current balance</p>
                  <div className="flex border-b-2 bg-elr-gray opacity-60">
                    <p className="text-3xl">{walletBalance}</p>
                    <p className="self-end mb-0.5 ml-2">NGN</p>
                  </div>
                </div>
                {balanceIsLow && (
                  <div className="flex flex-col gap-2 pt-6">
                    <div className="w-full">
                      <div
                        className={`${
                          showTransferDetails && 'bg-elr-gray rounded-xl'
                        }`}
                      >
                        {transferDetails.paystackAccountName && (
                          <ElrDropdownButton
                            text="Fund wallet via transfer"
                            className="h-7 text-10 bg-elr-gray-200/70"
                            size="xm"
                            onClick={() =>
                              setShowTransferDetails((prev) => !prev)
                            }
                            isToggled={showTransferDetails}
                          />
                        )}
                        <div
                          className={`${
                            showTransferDetails ? 'block' : 'hidden'
                          } px-5 py-2`}
                        >
                          <div className="flex flex-row pb-1">
                            <div className="flex flex-wrap gap-x-10 gap-y-2">
                              <span>
                                <p className="text-xs text-elr-black-200">
                                  Bank name
                                </p>
                                <p className="text-sm">Paystack Titan Micro</p>
                              </span>
                              <span>
                                <p className="text-xs text-elr-black-200">
                                  Account number
                                </p>
                                <p className="text-sm">
                                  {transferDetails.paystackAccountNumber}
                                </p>
                              </span>
                              <span>
                                <p className="text-xs text-elr-black-200">
                                  Account name
                                </p>
                                <p className="text-sm">
                                  {transferDetails.paystackAccountName}
                                </p>
                              </span>
                            </div>
                            <div className="p-1 mt-2 rounded bg-elr-gray-200 h-fit w-fit">
                              <ElrCopy
                                theme="dark"
                                content={
                                  transferDetails.paystackAccountNumber ?? ''
                                }
                              />
                            </div>
                          </div>
                          <div className="mx-auto w-fit">
                            <ElrButton
                              text="I have paid"
                              size="xm"
                              onClick={() => {
                                onPaymentInitiated(true);
                                onSuccess();
                              }}
                              className="bg-elr-black text-xs text-elr-gray px-2 py-1.5"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <ElrButton
                      text="Fund wallet via Paystack"
                      className="h-7 text-elr-purple text-10 bg-elr-purple bg-opacity-10"
                      size="xm"
                      disabled={disableFundButton()}
                      onClick={goToPaystack}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="pt-11">
                <p className="leading-9 text-16 text-lr-black-200">
                  Give us a few moments to check!
                </p>
                <div className="w-full h-1 mt-5 mb-6 bg-elr-gray">
                  <div className="w-1/4 h-1 animate-progress bg-elr-purple" />
                </div>
              </div>
            )}
          </div>
          {paymentInitiated && (
            <ElrButton
              text="Cancel transaction"
              className="p-4 py-1 text-sm bg-transparent border rounded-full h-9 text-elr-black w-148"
              size="xm"
              onClick={() => onPaymentInitiated(false)}
            />
          )}
          {!paymentInitiated && !balanceIsLow && (
            <ElrButton
              text="Make payment"
              onClick={onUserSelectWalletOption}
              className="w-full p-4 py-1 text-sm rounded-full bg-elr-black text-elr-white"
              size="lg"
            />
          )}
        </div>
      </div>
    </SlideIn>
  ) : (
    <FundWallet
      amount={estimatedAmountForRequest}
      close={setDisplayFundModal}
    />
  );
};
export default WalletSlideIn;
