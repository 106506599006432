import { useQueryClient } from '@tanstack/react-query';
import { SendRequest, SendRequestState } from 'app/api/d';
import { httpInitializePrivateRequest } from 'app/api/requests';
import { useGetEstimations, useGetStructureEstimate } from 'app/hooks/dispatch';
import { useGetProfile } from 'app/hooks/user';
import { defaultEstimateData, useRequestDataPayload } from 'app/hooks/utils';
import {
  ESTIMATE_CHECKOUT_PAYLOAD,
  STATE_DISPATCH_DETAILS,
  STATE_DISPATCH_PAYLOAD,
} from 'app/state/constants';
import { publicRoutes } from 'app/utilities/routes';
import {
  EstimateProps,
  GeoLocationData,
  HttpDispatchResponse,
  HttpLocationValues,
  LocationWrapperValues,
  UserDetailsValues,
} from 'app/utilities/types/shared';
import { estimateValidationSchema } from 'app/utilities/validationSchema';
import { useFormik } from 'formik';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ElrPageTitle } from 'ui-components';
import { TopNav } from 'app/components/menu/Navbar';
import { Estimate } from './components/estimate/Estimate';
import { UserDetails } from './components/user-details/UserDetails';
import PoliciesAppBanner from '../landing/components/PoliciesAppBanner';
import { Footer } from '../landing/components';

export interface Steps {
  [key: string]: ReactNode;
}

interface SubmitDeliverToInterface {
  name: string;
  phone: string;
  packageValue: string;
  packageType: { value: string; label: string };
}

type MakeRequestType = HttpLocationValues & {
  deliverToInformation: SubmitDeliverToInterface[];
  paymentOption: 'wallet';
};

export const DispatchOrder: React.FC<{}> = () => {
  const [, estimateRoutes] = useGetEstimations();
  const {
    id: userId,
    role,
    accountInformation: { id: roleId = '' } = {},
    ...profile
  } = useGetProfile();

  const [estimatePayload] = useGetStructureEstimate(
    estimateRoutes as EstimateProps
  );
  const [userRouteAddresses, setUserRouteAddress] =
    useState<LocationWrapperValues>(estimatePayload);
  const queryClient = useQueryClient();
  const [currentStep, setCurrentStep] = useState('estimate');
  const estimateCheckoutData: SendRequestState =
    queryClient.getQueryData([ESTIMATE_CHECKOUT_PAYLOAD]) ||
    defaultEstimateData;

  const fromState = useMemo(
    () => estimateCheckoutData.fromState,
    [estimateCheckoutData]
  );

  const [senderInformation, setSenderInformation] = useState<UserDetailsValues>(
    {
      state: '',
      email: profile.email,
      name: '',
      phone: '',
    }
  );
  const [geolocation] = useState<GeoLocationData>();

  const [requestSuccess, setRequestStatus] = useState<boolean>(false);
  const [requestDataPayload] = useRequestDataPayload();

  const dispatchDetails: HttpLocationValues | undefined = useMemo(
    () => queryClient.getQueryData([STATE_DISPATCH_DETAILS]),
    [queryClient]
  );

  const onSubmit = () => {};

  const { values, setValues } = useFormik<HttpLocationValues>({
    initialValues: {
      parcelCode: '',
      pickupLocation: dispatchDetails?.pickupLocation || {
        id: '',
        label: '',
        distance: 0,
        duration: 0,
      },
      dropoffLocations: dispatchDetails?.dropoffLocations || [
        { id: '', label: '', distance: 0, duration: 0 },
      ],
    },
    validationSchema: estimateValidationSchema,
    onSubmit,
  });

  const toggleElement = useCallback(() => {
    const getGBElement = document.getElementsByClassName(
      'gb-waw'
    ) as HTMLCollectionOf<HTMLElement>;
    if (getGBElement) {
      for (const el of getGBElement) {
        el.style.display = 'none';
      }
    }
  }, []);

  useEffect(() => {
    if (dispatchDetails) {
      setValues(dispatchDetails as any);
    }
    toggleElement();
  }, [dispatchDetails, setValues, toggleElement]);

  const handleEstimateSubmit = () => {
    setCurrentStep('userDetails');
  };

  const onEditPickupAddress = () => {
    setCurrentStep('estimate');
  };

  const onSetUserRouteAddresses = (routes: LocationWrapperValues) => {
    setUserRouteAddress(routes);
  };

  const MakeRequestInitiationCall = async (req: SendRequest) => {
    try {
      setRequestStatus(true);
      return authorizedRequestCall(req);
    } catch (error) {
      setRequestStatus(false);
      /** no op, but catch with sentry */
      return error;
    }
  };

  const authorizedRequestCall = async (payload: SendRequest) => {
    try {
      const init = await httpInitializePrivateRequest({
        userId,
        roleId,
        role,
        payload,
      });
      const redirectUrl = `${publicRoutes.dispatchDetails}/${init.request}?track=${init.trackingId}`;
      // history(redirectUrl, { replace: true });
      window.location.href = redirectUrl;
    } catch (error) {
      setRequestStatus(false);
      /** no op, but catch with sentry */
    }
  };

  const makeRequestDataPayload = (
    dispatchPayload: { geoId: string; batch: boolean },
    data: MakeRequestType
  ) => {
    /**
     * @todo
     * There's a bug here when user logs in and lands on estimate page
     * which is already populated, hence lat and lng will be undefined
     * fix is to refetch the coordinates.
     * temp fix is to return if no data, so they refresh.
     */
    if (!geolocation) return;

    const updatedSenderInfo = { ...senderInformation, state: fromState };

    const requestPayload = requestDataPayload({
      geoId: dispatchPayload.geoId,
      data,
      senderInformation: updatedSenderInfo,
      geolocation,
      batch: dispatchPayload.batch,
    });

    MakeRequestInitiationCall(requestPayload);
  };

  const steps: Steps = {
    estimate: (
      <Estimate
        initialValues={values}
        onSetUserRouteAddresses={onSetUserRouteAddresses}
        onSubmit={handleEstimateSubmit}
      />
    ),
    userDetails: (
      <UserDetails
        estimateRoutes={userRouteAddresses}
        onSubmit={(sender: UserDetailsValues) => {
          setSenderInformation(sender);
          setCurrentStep('dispatchDetails');
        }}
        onEditPickupAddress={onEditPickupAddress}
      />
    ),
    dispatchDetails: (
      <Estimate
        senderInformation={senderInformation}
        geolocation={geolocation}
        isDispatchDetails
        initialValues={values}
        requestSuccess={requestSuccess}
        onSubmit={(data: MakeRequestType) => {
          const dispatchPayload:
            | HttpDispatchResponse
            | {
                geoId: string;
                batch: boolean;
              } = queryClient.getQueryData([STATE_DISPATCH_PAYLOAD]) || {
            geoId: '',
            batch: false,
          };

          makeRequestDataPayload(dispatchPayload, data);
        }}
      />
    ),
  };

  const renderComponent = steps[currentStep];

  return (
    <div className="bg-white">
      <ElrPageTitle title="Errandlr - Estimate Your Delivery" />
      <TopNav isDefault />
      <div className="mx-5 py-10 md:mx-40">
        <div className="w-full bg-elr-gray rounded-md md:py-20 md:px-80 px-6 py-12 md:mt-20 mt-12 flex justify-center flex-col items-center">
          <p className="text-2xl font-semibold mb-6">Estimate delivery</p>
          <div className="flex flex-col items-center md:w-480 w-full">
            <div className="w-full">{renderComponent}</div>
          </div>
        </div>
        <PoliciesAppBanner />
      </div>
      <Footer hideTopFooter />
    </div>
  );
};
