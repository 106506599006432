import { CopyDoubleIcon } from 'app/components/SvgAssets';
import copyToClipboard from 'app/utilities/copyToClipboard';
import { toast } from 'react-toastify';
import { useGetProfile } from 'app/hooks/user';
import { ERRANDLR_REDIRECT_URL } from 'app/constants';

const BusinessDeliveryLink = () => {
  const {
    accountInformation: { slug = '' },
  } = useGetProfile();
  const businessDeliveryLink = `${ERRANDLR_REDIRECT_URL}/${slug}`;

  const handleCopy = () => {
    copyToClipboard(businessDeliveryLink);
    toast.success('Business delivery link copied to clipboard');
  };

  return (
    <div className="weight-normal text-sm relative border-elr-gray bg-white border-opacity-30 px-8 py-6">
      <p className="text-xl pb-1">Business Delivery Link</p>
      <div className="flex md:flex-row flex-col gap-3">
        <div className="bg-elr-gray py-3 px-5">
          <p className="text-elr-purple text-base">{businessDeliveryLink}</p>
        </div>
        <div
          className="flex bg-elr-gray py-3 px-6 gap-2 cursor-pointer justify-center align-middle"
          onClick={handleCopy}
        >
          <CopyDoubleIcon fill="#081120" />
          <p className="text-sm text-elr-black">Copy Link</p>
        </div>
      </div>
    </div>
  );
};

export default BusinessDeliveryLink;
