import { useLogout } from 'app/hooks/auth';
import { useGetProfile } from 'app/hooks/user';
import mappedNavigation from 'app/utilities/navigations';
import { privateRoutes } from 'app/utilities/routes';
import newIcon from 'assets/images/newIcon.svg';
import truncate from 'lodash/truncate';
import React from 'react';
import { Link } from 'react-router-dom';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import ProfilePicIcon from '@material-ui/icons/AccountCircleOutlined';
import DynamicIconDropDownMenu from '../DynamicIconDropDownMenu';

interface Props {
  containerClass?: string;
}

const TopNavigationMenu: React.FC<Props> = ({ containerClass = '' }) => {
  const [sendLogout] = useLogout();
  const { firstname = '', role, currentAccessRole } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const navigations = mappedNavigation(currentRole);
  const navRoute = (navigation: { title: string; route: string }) =>
    navigation.title === 'Settings'
      ? `/dispatcher/${privateRoutes.profile}`
      : navigation.route;

  return (
    <div
      className={`hidden md:flex items-center justify-end md:px-10 ${containerClass} w-full bg-white py-4`}
    >
      <div className="p-3 bg-elr-gray rounded-full">
        <NotificationsNoneIcon className="text-elr-gray-400" />
      </div>
      <div className="p-3 bg-elr-gray rounded-full mx-4">
        <Link to={privateRoutes.personalSupport}>
          <HelpIcon className="text-elr-gray-400" />
        </Link>
      </div>
      <div className="flex justify-center items-center py-1 px-4 bg-elr-gray rounded-full">
        <div className="p-2 bg-white rounded-full">
          <ProfilePicIcon className="text-elr-gray-400" />
        </div>
        <span className="text-elr-black ml-2">
          {truncate(firstname, { length: 10 })}
        </span>
        <DynamicIconDropDownMenu
          containerStyles="border-05 border-elr-green bg-white border-opacity-40 z-100"
          transformPositionClass="-translate-x-24"
        >
          <ul className="list-none bg-white text-elr-black text-opacity-40 text-sm">
            {navigations.map((navigation) => (
              <Link key={navigation.route} to={navRoute(navigation)}>
                <li className="cursor-pointer px-1 m-2 w-24 leading-7 hover:bg-elr-green hover:bg-opacity-10">
                  {navigation.title}
                </li>
              </Link>
            ))}
            <Link to={`/${currentRole}/${privateRoutes.bulkEstimate}`}>
              <li className="cursor-pointer px-1 m-2 w-24 leading-7 flex hover:bg-elr-green hover:bg-opacity-10">
                Bulk Order
                <span>
                  <img src={newIcon} alt="new icon" />
                </span>
              </li>
            </Link>
            <Link to={privateRoutes.selectPortal}>
              <li className="cursor-pointer px-1 m-2 w-24 leading-7 hover:bg-elr-green hover:bg-opacity-10">
                Select Portal
              </li>
            </Link>
            <div onClick={sendLogout}>
              <li className="cursor-pointer px-1 m-2 w-24 leading-7 hover:bg-elr-green hover:bg-opacity-10">
                Logout
              </li>
            </div>
          </ul>
        </DynamicIconDropDownMenu>
      </div>
    </div>
  );
};

export default TopNavigationMenu;
