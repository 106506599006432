import { useUpdateOutletType } from 'app/hooks/requests/commerce';
import { useGetProfile } from 'app/hooks/user';
import { privateRoutes } from 'app/utilities/routes';
import { OutletType } from 'app/utilities/types/shared';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { ElrButton } from 'ui-components';
import { ElrRadio } from 'ui-components/components/ElrRadio';
import {
  BasketIconWonWithBG,
  CalendarIconWonWithBG,
  CutleryIconWonWithBG,
  LeafIconWonWithBG,
  WearIconWonWithBG,
} from '../SvgAssets';

const EcommerceInitial = () => {
  const [selectedOutletType, setSelectedOutletType] = useState<
    Record<OutletType, boolean>
  >({
    market: false,
    logistics: false,
    essentials: false,
    product: false,
    event: false,
  });
  const navigate = useNavigate();
  const updateOutletTypeMutation = useUpdateOutletType();
  const [activeOutletType, setActiveOutletType] = useState<OutletType | null>(
    null
  );
  const { mutateAsync, status } = updateOutletTypeMutation;
  const profile = useGetProfile();

  const outletTypeToRouteMap: Record<OutletType, string> = {
    market: privateRoutes.marketRuns,
    logistics: '',
    essentials: '',
    product: '',
    event: privateRoutes.eventsList,
  };

  // useEffect(() => {
  //   if (profile?.accountInformation?.outletType) {
  //     setSelectedOutletType(profile?.accountInformation?.outletType);
  //   }
  // }, [profile]);

  const handleProceed = async () => {
    if (!selectedOutletType) return;
    const commerceId = profile.accountInformation.id;
    try {
      await mutateAsync({ outletType: selectedOutletType, commerceId });
      const route = outletTypeToRouteMap[activeOutletType as OutletType];
      navigate(route);
    } catch (error) {
      // Handle error
    }
  };

  const isLoading = status === 'pending';

  const onSelectOutletType = (outletType: OutletType) => {
    setSelectedOutletType({
      market: false,
      logistics: false,
      essentials: false,
      product: false,
      event: false,
      [outletType]: true,
    });
    setActiveOutletType(outletType);
  };

  return (
    <div className="flex justify-center">
      <div className="pt-20  mb-32">
        <header className="mb-6 text-center">
          <h1 className="text-2xl text-elr-black leading-7 font-normal mb-2">
            What do you want to sell?
          </h1>
          <p className="text-elr-black-400 text-lg leading-5">
            Select the option that applies to you
          </p>
        </header>

        <div className="grid grid-cols-column-two gap-4">
          <div
            className="bg-elr-white-400 py-5 px-6 cursor-pointer"
            onClick={() => onSelectOutletType('market')}
          >
            <div className="float-end">
              <ElrRadio
                checked={selectedOutletType.market}
                onChange={() => {}}
                radioClassName="accent-elr-black"
              />
            </div>
            <LeafIconWonWithBG />
            <div className="mb-6" />
            <p className="text-lg text-elr-black leading-5">Market Products</p>
            <p className="text-base text-elr-black-300">
              e.g Foodstuffs items in bulk
            </p>
          </div>
          <div className="bg-elr-white-400 py-5 px-6 opacity-50 cursor-pointer">
            <div className="float-end">
              <ElrRadio
                checked={false}
                onChange={() => {}}
                radioClassName="accent-elr-black"
              />
            </div>
            <CutleryIconWonWithBG />
            <div className="mb-6" />
            <p className="text-lg text-elr-black leading-5">Cooked Meals</p>
            <p className="text-base text-elr-black-300">
              e.g Restaurant Meals and Dishes
            </p>
          </div>
          <div className="bg-elr-white-400 py-5 px-6 opacity-50 cursor-pointer">
            <div className="float-end">
              <ElrRadio
                checked={false}
                onChange={() => {}}
                radioClassName="accent-elr-black"
              />
            </div>
            <BasketIconWonWithBG />
            <div className="mb-6" />
            <p className="text-lg text-elr-black leading-5">Essential Items</p>
            <p className="text-base text-elr-black-300">
              e.g Hair care products, Toiletries
            </p>
          </div>
          <div className="bg-elr-white-400 py-5 px-6 opacity-50 cursor-pointer">
            <div className="float-end">
              <ElrRadio
                checked={false}
                onChange={() => {}}
                radioClassName="accent-elr-black"
              />
            </div>
            <WearIconWonWithBG />
            <div className="mb-6" />
            <p className="text-lg text-elr-black leading-5">Lifestyle</p>
            <p className="text-base text-elr-black-300">
              e.g Clothes, shoes, accessories
            </p>
          </div>
          <div
            className="bg-elr-white-400 py-5 px-6 cursor-pointer"
            onClick={() => onSelectOutletType('event')}
          >
            <div className="float-end">
              <ElrRadio
                checked={selectedOutletType.event}
                onChange={() => {}}
                radioClassName="accent-elr-black"
              />
            </div>
            <CalendarIconWonWithBG />
            <div className="mb-6" />
            <p className="text-lg text-elr-black leading-5">Events</p>
            <p className="text-base text-elr-black-300">
              e.g Tickets to a rave, conferences, hangout
            </p>
          </div>
        </div>

        <div className="mt-14 mx-auto text-center">
          <ElrButton
            text={'Proceed'}
            spinnerColor="White"
            className="bg-elr-black text-elr-white-400 rounded-sm"
            onClick={handleProceed}
            disabled={!activeOutletType}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default EcommerceInitial;
