export const routeContainer = (isActive: boolean) =>
  `hidden md:flex flex-col md:flex-row justify-center md:justify-start mr-4 md:mr-0 items-center md:w-max md:h-10 h-20 w-20 hover:bg-elr-green hover:bg-opacity-10 md:hover:bg-elr-gray ${
    isActive
      ? 'text-elr-green text-opacity-90 bg-elr-green bg-opacity-10 md:bg-elr-gray'
      : ''
  }`;

export const navlinkStyle = (isActive: boolean) =>
  `flex flex-col md:flex-row justify-center md:justify-start items-start md:items-center ml-7 md:ml-0 mr-4 md:mr-0 md:mb-5 md:h-10 h-16 hover:bg-opacity-10 md:hover:bg-elr-gray md:px-3 ${
    isActive ? 'text-elr-black bg-elr-gray md:bg-elr-gray md:py-7' : ''
  }`;
